/* eslint-disable react/no-danger */
/* eslint-disable radix */
/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, DownOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {
  Alert,
  Badge,
  Button,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DebounceSelect } from '~/components/DebounceSelect';
import api from '~/services/api';
import history from '~/services/history';
import { WarrantyCheck } from '~/utils/warrantyCheck';

import Additional from './Additional';
import Battery from './Battery';
import ForPartsModal from './Components/ForPartsModal';
import Cosmetic from './Cosmetic';
import gradingRules from './grading';
import gradingRulesFunctions from './gradingFunctions';
import GraphicCard from './GraphicCard';
import HardDrive from './HardDrive';
import Hardware from './Hardware';
import Keyboard from './Keyboard';
import Software from './Software';
import { Container } from './styles';
import { assignKBType } from './utils/assignKBType';
import { ChangeStorageRam } from './utils/changeStorageRam';
import { fetchItems } from './utils/fetchItems';
import { handleItemResults } from './utils/handleItemResults';
import { reserveTypeOptions } from './utils/reserveTypeOptions';

const { Title } = Typography;
const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function AssessmentProcessing({ match, location }) {
  const profile = useSelector((state) => state.user.profile);

  const params = new URLSearchParams(location.search);

  const [form] = Form.useForm();
  const classes = useStyles();

  const [additionalFlag, setAdditionalFlag] = useState(false);
  const [assetDiagnosticId, setAssetDiagnosticId] = useState('');
  const [cameraOptions, setCameraOptions] = useState([]);
  const [changeBeingDone, setChangeBeingDone] = useState('');
  const [changeItemNotes, setChangeItemNotes] = useState('');
  const [confirmChangeModal, setConfirmChangeModal] = useState(false);
  const [confirmChangeModalForParts, setConfirmChangeModalForParts] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cosmeticsScreenOptions, setCosmeticsScreenOptions] = useState([]);
  const [disabledMessage, setDisabledMessage] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [gradeDescription, setGradeDescription] = useState('');
  const [gradeFunctionDescription, setGradeFunctionDescription] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [inventoryItemsId, setInventoryItemsId] = useState('');
  const [isAssignToSignedUser, setIsAssignToSignedUser] = useState(true);
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [isWarrantyVisible, setIsWarrantyVisible] = useState(false);
  const [itemCondition, setItemCondition] = useState('');
  const [itemDisabled, setItemDisabled] = useState(false);
  const [itemInfo, setItemInfo] = useState({ item: { brand: {} }, category: {}, cpu: {} });
  const [itemResults, setItemResults] = useState({});
  const [keyboardLanguageOptions, setKeyboardLanguageOptions] = useState([]);
  const [keyboardOptions, setKeyboardOptions] = useState([]);
  const [keyboardPortOptions, setKeyboardPortOptions] = useState([]);
  const [loading, setLoading] = useState();
  const [locationLog, setLocationLog] = useState([]);
  const [receivingId, setReceivingId] = useState();
  const [screenOptions, setScreenOptions] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');
  const [smartCardOptions, setSmartCardOptions] = useState([]);
  const [stylusOptions, setStylusOptions] = useState([]);
  const [warrantyCheck, setWarrantyCheck] = useState([]);
  const [checkUsb, setCheckUsb] = useState(false);
  const [checkGraphicsCard, setCheckGraphicsCard] = useState(false);
  const [checkCpu, setCheckCpu] = useState(false);
  const [returnToAssessment, setReturnToAssessment] = useState(false);

  const useBeforeUnload = ({ when, message }) => {
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = message;
        return message;
      };

      if (when) {
        window.addEventListener('beforeunload', handleBeforeUnload);
      }

      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [when, message]);
  };

  function getKBType(type, language) {
    if (language === 'EN') {
      switch (type) {
        case 'standard':
          return 3;
        case 'rubber':
          return 1;
        case 'emissive':
          return 5;
        default:
          return '';
      }
    } else if (language === 'Dual') {
      switch (type) {
        case 'standard':
          return 9;
        case 'rubber':
          return 8;
        case 'emissive':
          return 10;
        default:
          return '';
      }
    }
    switch (type) {
      case 'standard':
        return 4;
      case 'rubber':
        return 2;
      case 'emissive':
        return 6;
      default:
        return '';
    }
  }

  const postResultColor = {
    passed: 'success',
    new: 'success',
    failed: 'error',
    na: 'warning',
  };

  const handleSerialNumberSearch = useCallback(
    async (id) => {
      setLoading(true);
      const { data: unitData } = await api.get(`inventory_items/showById/${id}`);
      const serialNumber = unitData.serial_number;

      const itemUserInfo = await api.get(`inventory_items/getUserAssignedToItem/${id}`);

      const isItemAssignedToDifferentUser = itemUserInfo.data.userId !== profile.id;

      if (isItemAssignedToDifferentUser) {
        setLoading(false);
        setItemDisabled(true);

        setIsAssignToSignedUser(false);
      }

      setSerialNumber(serialNumber);
      form.resetFields();
      if (serialNumber === '') {
        toast.error('Enter the serial number first');
        setLoading(false);
        return;
      }

      const itemInventoryData = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumber });

      if (itemInventoryData.headers.itemnotfound) {
        alert('This unit is marked as Not Found. Put this item aside and inform manager');
      }

      if (itemInventoryData.data.error) {
        toast.error('Serial number not found');
        setItemInfo(null);
        setLoading(false);
        return;
      }

      if (itemInventoryData.data === null) {
        toast.error('Serial number not found');
        return;
      }

      setWarrantyCheck(await Promise.resolve(WarrantyCheck(serialNumber, itemInventoryData)));

      const assetDiagnosticData = await api.post('assetDiagnostic/showBySerialNumber', { serialNumber });

      switch (assetDiagnosticData.data?.certified_grade) {
        case 'C0':
          setGradeDescription('BIOS password');
          break;
        case 'C1':
        case 'C2':
          setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          break;
        case 'C3':
          setGradeDescription('Used fair, missing critical parts');
          break;
        case 'C4':
        case 'C5':
          setGradeDescription('Used very good, minor imperfections no affecting functionality');
          break;
        case 'C6':
          setGradeDescription('Used excellent, refurbished equipment in like-new condition');
          break;
        case 'C7':
        case 'C8':
          setGradeDescription('Unused, like new');
          break;
        case 'C9':
          setGradeDescription('New open box, original unsealed package');
          break;
        case 'C10':
          setGradeDescription('Brand New');
          break;
        default:
          setGradeDescription('');
          break;
      }

      setItemInfo(() => itemInventoryData.data);
      setInventoryItemsId(itemInventoryData.data.id);
      setAssetDiagnosticId(assetDiagnosticData.data?.id);
      setItemCondition(itemInventoryData.data.condition);

      const keyboardType = assignKBType(itemInventoryData.data.keyboard_type_id);

      let inventoryItemInfo = {
        acAdapter: itemInventoryData.data.ac_adapter,
        acAdapterAmp: itemInventoryData.data.ac_adapter_amp,
        acAdapterPartNumber: { value: itemInventoryData.data.ac_adapter_item?.id, label: itemInventoryData.data.ac_adapter_item?.name } || null,
        batteryFile: itemInventoryData.data.battery_file,
        batteryFile2: itemInventoryData.data.battery_file2,
        batteryFile3: itemInventoryData.data.battery_file3,
        batteryPartNumber: { value: itemInventoryData.data.battery_item_id, label: itemInventoryData.data.battery_item?.name },
        batteryPartNumber2: { value: itemInventoryData.data.battery_item_id2, label: itemInventoryData.data.battery_item2?.name },
        batteryPartNumber3: { value: itemInventoryData.data.battery_item_id3, label: itemInventoryData.data.battery_item3?.name },
        batteryPercentage: itemInventoryData.data.battery,
        batteryPercentage2: itemInventoryData.data.battery2,
        batteryPercentage3: itemInventoryData.data.battery3,
        batterySerialNumber: itemInventoryData.data.battery_serial_number?.toUpperCase(),
        batterySerialNumber2: itemInventoryData.data.battery_serial_number2?.toUpperCase(),
        batterySerialNumber3: itemInventoryData.data.battery_serial_number3?.toUpperCase(),
        cameraType: itemInventoryData.data.camera,
        coaReported: itemInventoryData.data.coa_reported,
        condition: itemInventoryData.data.condition,
        cosmeticsScreen: itemInventoryData.data.cosmetic_screen?.split(',').map(Number),
        cpuModel: { value: itemInventoryData.data.cpu?.id, label: itemInventoryData.data.cpu?.name } || null,
        dedicatedGpu: itemInventoryData.data.dedicated_gpu_model,
        graphicsCard: itemInventoryData.data.graphics_card,
        hours: itemInventoryData.data.hours,
        is_processed: itemInventoryData.data.is_processed,
        itemDescription: itemInventoryData.data.item.description,
        itemLocation: `${itemInventoryData.data?.locations?.name || ''} ${itemInventoryData.data?.usersLocation?.name || ''}`,
        keyboardLanguage: keyboardType.language,
        keyboardPorts: itemInventoryData.data.keyboard_ports?.split(',').map(Number),
        keyboardType: keyboardType.type,
        name: itemInventoryData.data.processed_by,
        notes: itemInventoryData.data.description,
        operatingSystem: itemInventoryData.data.operating_system,
        originalCoa: itemInventoryData.data.original_coa,
        post_result: itemInventoryData.data.post_result,
        ramSerialNumber1: itemInventoryData.data.ram_serial_number?.trim()?.toUpperCase(),
        ramSerialNumber2: itemInventoryData.data.ram_serial_number_2?.trim()?.toUpperCase(),
        ramSize1: { value: itemInventoryData.data.ram, label: itemInventoryData.data.ram } || null,
        ramSize2: { value: itemInventoryData.data.ram_2, label: itemInventoryData.data.ram_2 } || null,
        screenType: itemInventoryData.data.touch,
        serialNumber: serialNumber.toUpperCase(),
        smartCardType: itemInventoryData.data.smart_card_type,
        storageHealth1: itemInventoryData.data.storage_health,
        storageHealth2: itemInventoryData.data.storage_health_2,
        storageSerialNumber1: itemInventoryData.data.storage_serial_number?.trim()?.toUpperCase(),
        storageSerialNumber2: itemInventoryData.data.storage_serial_number_2?.trim()?.toUpperCase(),
        storageSize1: { value: itemInventoryData.data.storage, label: itemInventoryData.data.storage } || null,
        storageSize2: { value: itemInventoryData.data.storage_2, label: itemInventoryData.data.storage_2 } || null,
        stylusType: itemInventoryData.data.stylus,
        usbPorts: itemInventoryData.data.usb_ports,
        windowsActivated: itemInventoryData.data.windows_activated,
        wipingReportFile: itemInventoryData.data.wiping_report_file,
        wwanModel: itemInventoryData.data.wwan_model,
      };

      if (assetDiagnosticData.data !== null) {
        inventoryItemInfo = {
          ...inventoryItemInfo,
          certifiedGrade: assetDiagnosticData.data.certified_grade || 'na',
          grade: itemInventoryData.data.status === 'Failed' ? 'FA' : assetDiagnosticData.data.company_grade || 'N/A',
          resultStorage1: assetDiagnosticData.data.function_storage || 'na',
          resultRam1: assetDiagnosticData.data.function_ram || null,
          resultStorage2: assetDiagnosticData.data.function_storage_2 || 'na',
          resultRam2: assetDiagnosticData.data.function_ram_2 || null,
          resultDiagnosticUtility: assetDiagnosticData.data.function_diagnostic_utility || 'na',
          resultScreen: assetDiagnosticData.data.function_screen || 'na',
          resultLcdScreen: assetDiagnosticData.data.function_lcd_screen || 'na',
          resultStylus: assetDiagnosticData.data.cosmetics_stylus || 'na',
          resultMousePad: assetDiagnosticData.data.function_mousepad || 'na',
          resultUsb: assetDiagnosticData.data.function_usb || 'na',
          resultWlan: assetDiagnosticData.data.function_wlan || 'na',
          resultBluetooth: assetDiagnosticData.data.function_bluetooth || 'na',
          resultAudio: assetDiagnosticData.data.function_audio || 'na',
          resultKeyboard: assetDiagnosticData.data.function_keyboard || 'na',
          resultBattery: assetDiagnosticData.data.function_battery || null,
          resultBattery2: assetDiagnosticData.data.function_battery2 || null,
          resultBattery3: assetDiagnosticData.data.function_battery3 || null,
          resultOpticalDrive: assetDiagnosticData.data.function_optical_drive || 'na',
          resultWwan: assetDiagnosticData.data.function_wwan || 'na',
          resultGps: assetDiagnosticData.data.function_gps || 'na',
          resultCamera: assetDiagnosticData.data.function_camera || 'na',
          resultFaceRecognition: assetDiagnosticData.data.function_face_recognition || 'na',
          resultRfid: assetDiagnosticData.data.function_rfid || 'na',
          resultFingerprint: assetDiagnosticData.data.function_fingerprint || 'na',
          resultSmartCard: assetDiagnosticData.data.function_smartcard || 'na',
          resultBarcodeReader: assetDiagnosticData.data.function_barcode_reader || 'na',
          resultOperatingSystem: assetDiagnosticData.data.function_operating_system || 'na',
          resultWindowsActivation: assetDiagnosticData.data.function_windows_activation ? 'passed' : 'na',
          resultCoaReport: assetDiagnosticData.data.function_coa_report || 'na',
          resultDriversInstalled: assetDiagnosticData.data.function_drivers_installed || 'na',
          resultStorageExtended: assetDiagnosticData.data.function_storage_extended ? 'passed' : 'na',
          resultRecoveryMedia: assetDiagnosticData.data.function_recovery_media || 'na',
          resultCfc2BiosUpdate: assetDiagnosticData.data.function_cfc2_bios_update || 'na',
          resultCovers: assetDiagnosticData.data.cosmetics_covers || 'na',
          resultScrews: assetDiagnosticData.data.cosmetics_screws || 'na',
          resultTetherStylus: assetDiagnosticData.data.cosmetics_tether || 'na',
          resultCosmeticsScreen: assetDiagnosticData.data.cosmetics_screen || 'na',
          resultCosmeticsBattery: assetDiagnosticData.data.cosmetics_battery || 'na',
          resultAcAdapter: assetDiagnosticData.data.cosmetics_ac_adapter || 'na',
          visualAppearance: assetDiagnosticData.data.cosmetics_visual_appearance || null,
          labelCondition: assetDiagnosticData.data.cosmetics_label_condition || null,
          dentsScratches: assetDiagnosticData.data.cosmetics_dents_scratches || null,
          discoloration: assetDiagnosticData.data.cosmetics_discoloration || null,
          missingParts: assetDiagnosticData.data.cosmetics_missing_parts === false ? 'no' : 'yes',
          latchesHingesDamaged: assetDiagnosticData.data.cosmetics_latches_hinges_damaged === false ? 'no' : 'yes',
          cleaned: assetDiagnosticData.data.cosmetics_cleaned === false ? 'no' : 'yes',
          packaging: assetDiagnosticData.data.cosmetics_packaging || null,
          biosPassword: assetDiagnosticData.data.bios_password || null,
          cracks: assetDiagnosticData.data.cosmetics_cracks || null,
          resultWipingReport: assetDiagnosticData.data.result_wiping_report || null,
          resultBatteryReport: assetDiagnosticData.data.result_battery_report || null,
          resultBatteryReport2: assetDiagnosticData.data.result_battery_report3 || null,
          resultBatteryReport3: assetDiagnosticData.data.result_battery_report3 || null,
          technicianName: assetDiagnosticData.data.user.name || 'na',
          resultGraphicsCard: assetDiagnosticData.data.function_graphics_card || 'na',
          resultDedicatedGpu: assetDiagnosticData.data.function_dedicated_gpu || 'na',
          resultKeyboardPorts: assetDiagnosticData.data.function_keyboard_ports || 'na',
          resultCPU: assetDiagnosticData.data.function_cpu || null,
        };
      }
      form.setFieldsValue(inventoryItemInfo);
      setItemResults(inventoryItemInfo);

      /**
       * Checking the functionality grade, since is not storaged in the database
       */
      if (itemInventoryData.data.status === 'Failed') {
        if (
          form.getFieldValue('resultStorage1') === 'failed' ||
          form.getFieldValue('resultRam1') === 'failed' ||
          form.getFieldValue('resultStorage2') === 'failed' ||
          form.getFieldValue('resultRam2') === 'failed' ||
          form.getFieldValue('resultBattery') === 'failed' ||
          form.getFieldValue('resultGraphicsCard') === 'failed' ||
          form.getFieldValue('resultDedicatedGpu') === 'failed' ||
          form.getFieldValue('resultScreen') === 'failed' ||
          form.getFieldValue('resultLcdScreen') === 'failed' ||
          form.getFieldValue('resultMousePad') === 'failed' ||
          form.getFieldValue('resultKeyboard') === 'failed' ||
          form.getFieldValue('resultCPU') === 'failed'
        ) {
          setGradeFunctionDescription(['FA', 'Key Functions not working']);
        } else {
          setGradeFunctionDescription(['F3', 'Key Functions Working, May be missing components not essential to key functions']);
        }
      } else if (
        form.getFieldValue('resultStorage1') === 'na' ||
        form.getFieldValue('resultRam1') === 'na' ||
        form.getFieldValue('resultStorage2') === 'na' ||
        form.getFieldValue('resultRam2') === 'na' ||
        form.getFieldValue('resultBattery') === 'na'
      ) {
        setGradeFunctionDescription(['F3', 'Key Functions Working, May be missing components not essential to key functions']);
      } else if (form.getFieldValue('resultOperatingSystem') === 'na') {
        setGradeFunctionDescription(['F4', 'Hardware Functional, No missing or damaged components or parts, Software not Loaded']);
      } else {
        setGradeFunctionDescription(['F5', 'Refurbished, No missing or damaged components or parts, Software Loaded']);
      }
      setLoading(false);
    },
    [form, profile.id]
  );

  useEffect(() => {
    async function loadDropdownboxes() {
      const instructionsData = await api.get('instructions');
      const otherPortsList = await api.get('other_ports_options');
      const cosmeticScreenData = await api.get('cosmetic_screen_options');

      setInstructions(instructionsData.data);

      setScreenOptions([
        { label: 'Touchscreen', value: 'touchscreen' },
        { label: 'Digitizer', value: 'digitizer' },
        { label: 'Dual Touch', value: 'dual_touch' },
        { label: 'Non-touch', value: 'non_touch' },
      ]);

      setStylusOptions([
        { label: 'Touchscreen', value: 'touchscreen' },
        { label: 'Digitizer', value: 'digitizer' },
      ]);

      setKeyboardOptions([
        { label: 'Standard', value: 'standard' },
        { label: 'Emissive', value: 'emissive' },
        { label: 'Rubber', value: 'rubber' },
      ]);

      setKeyboardLanguageOptions([
        { label: 'English', value: 'EN' },
        { label: 'French', value: 'CA-FR' },
        { label: 'Dual', value: 'Dual' },
      ]);

      setCameraOptions([
        { label: 'Front', value: 'front' },
        { label: 'Rear', value: 'rear' },
        { label: 'Dual Camera', value: 'dualCamera' },
      ]);

      setSmartCardOptions([
        { label: 'Insertable', value: 'insertable' },
        { label: 'Contactless', value: 'contactless' },
        { label: 'Both', value: 'insertable, contactless' },
      ]);

      setCosmeticsScreenOptions(
        cosmeticScreenData.data
          .filter((port) => port.active === true)
          .map((port) => ({
            value: port.id,
            label: port.name,
            severity: port.severity,
          }))
      );

      setKeyboardPortOptions(
        otherPortsList.data
          .filter((port) => port.active === true)
          .map((port) => ({
            value: port.id,
            label: port.name,
          }))
      );
    }
    loadDropdownboxes();
    // searchInputRef.current.focus();

    const additional = params.get('additional');
    const receiving_id = params.get('receivingId');
    setAdditionalFlag(additional === 'true');
    setReceivingId(receiving_id);
  }, []); //eslint-disable-line

  useEffect(() => {
    // COMING FROM ORDER + HISTORY
    if (match.params.id !== null && typeof match.params.id !== 'undefined') {
      handleSerialNumberSearch(match.params.id);
    }
  }, [match]); //eslint-disable-line

  const onFinishFailed = ({ _values, errorFields, _outOfDate }) => {
    errorFields.forEach((el) => {
      const message = el.errors[0] === ' ' ? `${el.name[0]} has an error message` : el.errors[0];
      toast.error(message);
    });
  };

  function handleFormChange() {
    setFormChanged(true);
  }

  const calculateGrade = useCallback(
    (packaging, biosPassword, labelCondition, dentsScratches, discoloration, missingParts, latchesHingesDamaged, hours, cracks, cosmeticsScreen) => {
      let cosmeticScreenSeverity = 'low';
      if (cosmeticsScreen !== undefined) {
        const cosmeticScreenSeverityList = cosmeticsScreen.map((id) => cosmeticsScreenOptions.find((screen) => screen.value === id).severity);
        cosmeticScreenSeverity = cosmeticScreenSeverityList.includes('high') ? 'high' : cosmeticScreenSeverityList.includes('moderate') ? 'moderate' : 'low';
      }

      if (itemInfo.item.subcategory?.id === 10 && form.getFieldValue('resultKeyboard') === 'failed') {
        form.setFieldsValue({ certifiedGrade: 'FA' });
        return { certified_grade: 'FA', company_grade: 'FA' };
      }

      if (itemInfo?.item?.category?.id === 6) {
        return { certified_grade: 'na', company_grade: 'U', condition: 'Used' };
      }
      if (itemInfo.post_result === 'new' || itemInfo.condition === 'New') {
        return { certified_grade: 'C10', company_grade: 'New', condition: 'New' };
      }

      if (biosPassword === 'yes') {
        setGradeDescription('BIOS password');
        return { certified_grade: 'C0', company_grade: 'FA', condition: '', status: 'failed' };
      }

      if (
        biosPassword === 'no' &&
        cracks === 'fixable-noCracks' &&
        labelCondition === 'excellent' &&
        dentsScratches === 'none' &&
        discoloration === 'none' &&
        missingParts === 'no' &&
        latchesHingesDamaged === 'no' &&
        packaging === 'unsealedFactory' &&
        +hours <= 10
      ) {
        if (![1, 2, 3, 10].includes(itemInfo?.item?.category?.id)) {
          // If the item doesn't have screen, don't need to check screen damage
          setGradeDescription('New open box, original unsealed package');
          return { certified_grade: 'C9', company_grade: 'OB', condition: 'Open Box' };
        }

        if (cosmeticScreenSeverity === 'low') {
          setGradeDescription('New open box, original unsealed package');
          return { certified_grade: 'C9', company_grade: 'OB', condition: 'Open Box' };
        }
      }

      const grade = { certified_grade: 'C9', company_grade: 'A', condition: 'Refurbished' };

      if (cracks === 'unfixable') {
        grade.certified_grade = 'C2';
        grade.company_grade = 'D';
        setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
        return grade;
      }

      if (missingParts === 'yes' || latchesHingesDamaged === 'yes' || cosmeticScreenSeverity === 'moderate') {
        grade.certified_grade = 'C3';
        grade.company_grade = 'C';
        setGradeDescription('Used fair, missing critical parts');
      }

      if (cosmeticScreenSeverity === 'high') {
        grade.certified_grade = 'C1';
        grade.company_grade = 'D';
        setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
      }

      switch (labelCondition) {
        case 'excellent':
          if (grade.certified_grade > 'C6') {
            if (+hours <= 10) {
              grade.certified_grade = 'C8';
              grade.company_grade = 'A';
              setGradeDescription('Unused, like new');
            } else {
              grade.certified_grade = 'C6';
              grade.company_grade = 'A';
              setGradeDescription('Used excellent, refurbished equipment in like-new condition');
            }
          }
          break;
        case 'good':
          if (grade.certified_grade > 'C5') {
            grade.certified_grade = 'C5';
            grade.company_grade = 'B';
            setGradeDescription('Used very good, minor imperfections no affecting functionality');
          }
          break;
        case 'fair':
          if (grade.certified_grade > 'C3') {
            grade.certified_grade = 'C3';
            grade.company_grade = 'C';
            setGradeDescription('Used fair, missing critical parts');
          }
          break;
        case 'poor':
          if (grade.certified_grade > 'C2') {
            grade.certified_grade = 'C2';
            grade.company_grade = 'D';
            setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          }
          break;
        default:
          break;
      }

      switch (dentsScratches) {
        case 'none':
          if (grade.certified_grade > 'C8') {
            grade.certified_grade = 'C8';
            grade.company_grade = 'A';
            if (+hours <= 10) setGradeDescription('Unused, like new');
            else setGradeDescription('Used excellent, refurbished equipment in like-new condition');
          }
          break;
        case 'minor':
          if (grade.certified_grade > 'C5') {
            grade.certified_grade = 'C5';
            grade.company_grade = 'B';
            setGradeDescription('Used very good, minor imperfections no affecting functionality');
          }
          break;
        case 'moderate':
          if (grade.certified_grade > 'C3') {
            grade.certified_grade = 'C3';
            grade.company_grade = 'C';
            setGradeDescription('Used fair, missing critical parts');
          }
          break;
        case 'major':
          if (grade.certified_grade > 'C2') {
            grade.certified_grade = 'C2';
            grade.company_grade = 'D';
            setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          }
          break;
        default:
          break;
      }

      switch (discoloration) {
        case 'none':
          if (grade.certified_grade > 'C8') {
            grade.certified_grade = 'C8';
            grade.company_grade = 'A';
            if (+hours <= 10) setGradeDescription('Unused, like new');
            else setGradeDescription('Used excellent, refurbished equipment in like-new condition');
          }
          break;
        case 'minor':
          if (grade.certified_grade > 'C5') {
            grade.certified_grade = 'C5';
            grade.company_grade = 'B';
            setGradeDescription('Used very good, minor imperfections no affecting functionality');
          }
          break;
        case 'moderate':
          if (grade.certified_grade > 'C3') {
            grade.certified_grade = 'C3';
            grade.company_grade = 'C';
            setGradeDescription('Used fair, missing critical parts');
          }
          break;
        case 'major':
          if (grade.certified_grade > 'C2') {
            grade.certified_grade = 'C2';
            grade.company_grade = 'D';
            setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          }
          break;
        default:
          break;
      }

      return grade;
    },
    [itemInfo, cosmeticsScreenOptions] //eslint-disable-line
  );

  function handleAssetDiagnosticData(values) {
    return {
      bios_password: values.biosPassword,
      cosmetics_ac_adapter: values.resultAcAdapter,
      cosmetics_battery: values.resultCosmeticsBattery,
      cosmetics_cleaned: values.cleaned === 'yes',
      cosmetics_covers: values.resultCovers,
      cosmetics_cracks: values.cracks,
      cosmetics_dents_scratches: values.dentsScratches,
      cosmetics_discoloration: values.discoloration,
      cosmetics_label_condition: values.labelCondition,
      cosmetics_latches_hinges_damaged: values.latchesHingesDamaged === 'yes',
      cosmetics_missing_parts: values.missingParts === 'yes',
      cosmetics_packaging: values.packaging,
      cosmetics_screen: values.resultCosmeticsScreen,
      cosmetics_screws: values.resultScrews,
      cosmetics_stylus: values.resultStylus,
      cosmetics_tether: values.resultTetherStylus,
      cosmetics_visual_appearance: values.visualAppearance,
      function_audio: values.resultAudio,
      function_barcode_reader: values.resultBarcodeReader,
      function_battery: values.resultBattery,
      function_battery2: values.resultBattery2,
      function_battery3: values.resultBattery3,
      function_bluetooth: values.resultBluetooth,
      function_camera: values.resultCamera,
      function_cfc2_bios_update: values.resultCfc2BiosUpdate,
      function_coa_report: values.resultCoaReport,
      function_cpu: values.resultCPU,
      function_dedicated_gpu: values.resultDedicatedGpu,
      function_diagnostic_utility: values.resultDiagnosticUtility,
      function_drivers_installed: values.resultDriversInstalled,
      function_face_recognition: values.resultFaceRecognition,
      function_fingerprint: values.resultFingerprint,
      function_gps: values.resultGps,
      function_graphics_card: values.resultGraphicsCard,
      function_keyboard_ports: values.resultKeyboardPorts,
      function_keyboard: values.resultKeyboard,
      function_lcd_screen: values.resultLcdScreen,
      function_mousepad: values.resultMousePad,
      function_operating_system: values.resultOperatingSystem,
      function_optical_drive: values.resultOpticalDrive,
      function_ram_2: values.resultRam2,
      function_ram: values.resultRam1,
      function_recovery_media: values.resultRecoveryMedia,
      function_rfid: values.resultRfid,
      function_screen: values.resultScreen,
      function_smartcard: values.resultSmartCard,
      function_storage_2: values.resultStorage2,
      function_storage_extended: values.resultStorageExtended === 'passed',
      function_storage: values.resultStorage1,
      function_usb: values.resultUsb,
      function_windows_activation: values.resultWindowsActivation === 'passed',
      function_wlan: values.resultWlan,
      function_wwan: values.resultWwan,
      inventory_items_id: inventoryItemsId,
      result_battery_report: values.resultBatteryReport,
      result_battery_report2: values.resultBatteryReport2,
      result_battery_report3: values.resultBatteryReport3,
      result_wiping_report: values.resultWipingReport,
      serial_number: serialNumber.toUpperCase(),
      user_id: profile.id,
    };
  }

  function handleInventoryItemData(values) {
    return {
      ac_adapter_amp: values.acAdapterAmp || null,
      ac_adapter_item_id: values?.acAdapterPartNumber?.value || null,
      ac_adapter: values.acAdapter || null,
      battery_item_id: typeof values.batteryPartNumber === 'object' ? values.batteryPartNumber?.value : values.batteryPartNumber || null,
      battery_item_id2: typeof values.batteryPartNumber2 === 'object' ? values.batteryPartNumber2?.value : values.batteryPartNumber2 || null,
      battery_item_id3: typeof values.batteryPartNumber3 === 'object' ? values.batteryPartNumber3?.value : values.batteryPartNumber3 || null,
      battery_file_id: values?.batteryFile !== undefined && values?.batteryFile?.length > 0 ? values.batteryFile[0]?.uid : null || null,
      battery_file_id2: values?.batteryFile2 !== undefined && values?.batteryFile2?.length > 0 ? values.batteryFile2[0]?.uid : null || null,
      battery_file_id3: values?.batteryFile3 !== undefined && values?.batteryFile3?.length > 0 ? values.batteryFile3[0]?.uid : null || null,
      battery_serial_number: values?.batterySerialNumber?.toUpperCase() || null,
      battery_serial_number2: values?.batterySerialNumber2?.toUpperCase() || null,
      battery_serial_number3: values?.batterySerialNumber3?.toUpperCase() || null,
      battery: values.batteryPercentage || null,
      battery2: values.batteryPercentage2 || null,
      battery3: values.batteryPercentage3 || null,
      camera: values.cameraType || null,
      coa_reported: values.coaReported || null,
      cosmetic_screen: values.cosmeticsScreen?.sort().join() || null,
      cpu_id: typeof values.cpuModel === 'object' ? values.cpuModel?.value : values.cpuModel || null,
      dedicated_gpu_model: values.dedicatedGpu || null,
      description: values.notes,
      graphics_card: values.graphicsCard || null,
      hours: values.hours ?? null,
      keyboard_ports: values.keyboardPorts?.sort().join() || null,
      keyboard_type_id: getKBType(values.keyboardType, values.keyboardLanguage) || null,
      operating_system: values.operatingSystem || null,
      original_coa: values.originalCoa || null,
      processed_by: profile.name || null,
      ram_2: typeof values.ramSize2 === 'object' ? values.ramSize2?.value : values.ramSize2 || null,
      ram_serial_number_2: values.ramSerialNumber2?.toUpperCase() || null,
      ram_serial_number: values.ramSerialNumber1?.toUpperCase() || null,
      ram: typeof values.ramSize1 === 'object' ? values.ramSize1?.value : values.ramSize1 || null,
      serial_number: serialNumber.toUpperCase(),
      smart_card_type: values.smartCardType || null,
      storage_2: typeof values.storageSize2 === 'object' ? values.storageSize2?.value : values.storageSize2 || null,
      storage_health_2: values.storageHealth2,
      storage_health: values.storageHealth1,
      storage_serial_number_2: values.storageSerialNumber2?.trim().toUpperCase() || null,
      storage_serial_number: values.storageSerialNumber1?.trim().toUpperCase() || null,
      storage: typeof values.storageSize1 === 'object' ? values.storageSize1?.value : values.storageSize1 || null,
      stylus: values.stylusType || null,
      touch: values.screenType || null,
      usb_ports: values.usbPorts || null,
      windows_activated: values.windowsActivated || null,
      wiping_report_file_id: values.wipingReportId,
      wwan_model: values.wwanModel || null,
    };
  }

  const onFinish = async (values) => {
    try {
      const currentItemStatus = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumber });
      if (currentItemStatus.data.status === 'Sold') {
        toast.error('Item is Sold');
        return;
      }
      const grade = calculateGrade(
        values.packaging,
        values.biosPassword || 'no',
        values.labelCondition,
        values.dentsScratches,
        values.discoloration,
        values.missingParts,
        values.latchesHingesDamaged,
        values.hours,
        values.cracks,
        values.cosmeticsScreen
      );

      const assetDiagnosticData = handleAssetDiagnosticData(values);
      assetDiagnosticData.certified_grade = grade?.certified_grade || 'na';
      assetDiagnosticData.company_grade = grade?.company_grade || 'na';

      const inventoryItemData = handleInventoryItemData(values);
      inventoryItemData.action = 'completeInspection';
      inventoryItemData.grade = grade?.company_grade || 'TBI';
      inventoryItemData.condition = grade?.condition || itemInfo.condition;
      inventoryItemData.status =
        itemInfo?.status !== 'AwaitingShipment' && itemInfo?.status !== 'InUse' && itemInfo?.status !== 'Reserved' ? 'InStock' : itemInfo?.status;
      inventoryItemData.is_processed = true;
      const itemResultsData = handleItemResults(values, inventoryItemsId);
      setItemResults(itemResultsData);

      /**
       * Removing Cosmetics from status calculation equation
       */
      ['resultCovers', 'resultScrews', 'resultTetherStylus', 'resultCosmeticsScreen', 'resultCosmeticsBattery', 'resultAcAdapter'].forEach((e) => {
        delete values[e];
      });

      if (grade?.status === 'failed' || Object.values(values).find((el) => el === 'failed') === 'failed') {
        inventoryItemData.status = 'Failed';
        inventoryItemData.grade = 'FA';
        inventoryItemData.condition = '';
      }

      if (itemInfo.item.category?.id === 6) {
        inventoryItemData.status = values.resultWipingReport === 'passed' ? 'InStock' : 'Failed';
      }

      inventoryItemData.last_inspection_at = new Date();

      await api.put('inventory_items/completeInspection', { items: [inventoryItemData], assetDiagnosticData });
      setFormChanged(() => false);
      setItemInfo(() => ({
        ...itemInfo,
        status: inventoryItemData.status,
        grade: inventoryItemData.grade,
        last_inspection_at: inventoryItemData.last_inspection_at,
        condition: inventoryItemData.condition,
        post_result: inventoryItemData.post_result,
        is_processed: true,
      }));

      toast.success('Item registered successfully');
      setReturnToAssessment(true);
    } catch (error) {
      toast.error(`${error.response.data.message}. Something went wrong. Contact support to explain what happened.`);
    }
  };

  const handleClear = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (returnToAssessment) {
      history.push(additionalFlag === true ? `/lotAssessment?id=${receivingId}&additionalFlag=true` : `/lotAssessment?id=${receivingId}`);
    }
  }, [returnToAssessment]); // eslint-disable-line

  useEffect(() => {
    if (isAssignToSignedUser === true) {
      const statusAllowedToEdit = ['AwaitingShipment', 'Failed', 'InStock', 'NotFound', 'ToBeInspected', 'ToBeInspected-RMA'];

      if (itemInfo?.item?.category?.id === 6 && itemInfo?.condition === 'New') {
        setDisabledMessage('This item cannot be edited due to its status or condition');
        setItemDisabled(true);
        return;
      }

      if (statusAllowedToEdit.includes(itemInfo.status)) {
        setDisabledMessage(null);
        setItemDisabled(false);
        return;
      }

      setDisabledMessage('This item cannot be edited due to its status or condition. Code: 1');
      setItemDisabled(true);
    } else {
      setDisabledMessage('This item cannot be edited because is not under your name');
      setItemDisabled(true);
    }
  }, [itemInfo]); //eslint-disable-line

  // TODO: Check this function
  const handleChangeToForParts = async (selectedOptions, qrCode) => {
    try {
      setConfirmLoading(true);
      const ALL_CHECKBOXES_WERE_NOT_CHECKED = selectedOptions.length < 3;
      if (ALL_CHECKBOXES_WERE_NOT_CHECKED) {
        toast.error('Please check all the boxes to continue with this action.');
        setConfirmLoading(false);
        return;
      }

      const QR_CODE_NOT_SCANNED = qrCode === '';
      if (QR_CODE_NOT_SCANNED) {
        toast.error('Please ask your supervisor for authorization.');
        setConfirmLoading(false);
        return;
      }

      const CHECK_IF_IT_IS_A_USER = qrCode.substring(0, 4) === 'USR:';
      if (CHECK_IF_IT_IS_A_USER) {
        const qrCodeFormated = qrCode.substring(qrCode.indexOf('USR:') + 4);
        const possibleAuthorizedUser = await api.get(`locations/validateUserQrCode/${qrCodeFormated}`);
        const techManagerCheck = await api.get(`isTechManager/${possibleAuthorizedUser.data.user.id}`);
        const isTechManager = techManagerCheck.data.isTechManager;

        const POSSIBLE_AUTHORIZED_USER_ERROR = possibleAuthorizedUser.data.error;
        if (POSSIBLE_AUTHORIZED_USER_ERROR) {
          toast.error(POSSIBLE_AUTHORIZED_USER_ERROR);
          return;
        }

        const IS_AUTHORIZED_USER = possibleAuthorizedUser.data.user.type === 999 || isTechManager;
        if (IS_AUTHORIZED_USER) {
          await api.patch(`inventory_items/forParts/${itemInfo.id}/${assetDiagnosticId}`);

          setConfirmLoading(false);
          // searchInputRef.current.value = '';
          // searchInputRef.current.focus();
          setSerialNumber('');
          setItemInfo(() => ({ item: { brand: {} }, category: {}, cpu: {} }));

          setConfirmChangeModalForParts(false);
          toast.success('Item condition was changed to "For Parts" and will not be available anymore');
          history.push('/inventoryProcessing');
          return;
        }
        toast.error('QR code not authorized. Please ask your supervisor for authorization.');
        setConfirmLoading(false);
      }
    } catch (error) {
      toast.error(`Something went wrong. Contact administrator to report this message. ${error.response.data.error}`);
      // Something went wrong. Contact administrator to report this message. Cannot read properties of null (reading 'dataValu
    }
  };

  const handleChangeReserve = async (type) => {
    const values = form.getFieldsValue();
    try {
      const reservation = { serial_number: serialNumber.toUpperCase(), notes: changeItemNotes };
      reservation.status = type;
      reservation.action = 'changeToReserved';
      reservation.description = `${values.notes} ${changeItemNotes}`;

      setItemInfo(() => ({ ...itemInfo, status: type }));
      form.setFieldsValue({ notes: `${values.notes} ${changeItemNotes}` });

      setConfirmLoading(false);
      await api.put('inventory_items', { items: [reservation] });
      toast.success('Item changed status.');
    } catch (error) {
      toast.error(`${error}. Contact administrator to report this message`);
    }
  };

  const handleChangeUnreserve = async () => {
    try {
      const reservation = { serial_number: serialNumber.toUpperCase(), notes: changeItemNotes };
      if (itemCondition === 'New') {
        reservation.status = 'InStock';
        reservation.action = 'changeToUnreserved';

        setItemInfo(() => ({ ...itemInfo, status: 'InStock' }));
      } else {
        reservation.status = 'InStock';
        reservation.action = 'changeToUnreserved';
        reservation.is_processed = false;
        setItemInfo(() => ({ ...itemInfo, is_processed: false, status: 'InStock' }));
        form.setFieldsValue({ is_processed: false });
      }

      setConfirmLoading(false);
      await api.put('inventory_items', { items: [reservation] });
      toast.success('Item changed status.');
    } catch (error) {
      toast.error(`${error}. Contact administrator to report this message`);
    }
  };

  const handleChangeNew = async () => {
    try {
      await api.put('assetDiagnostic', {
        serial_number: serialNumber.toUpperCase(),
        user_id: profile.id,
        inventory_items_id: inventoryItemsId,
        company_grade: 'New',
        certified_grade: 'C10',
      });
      const postValue = form.getFieldValue('postResult') || 'new';
      const changeNew = {
        serial_number: serialNumber.toUpperCase(),
        notes: changeItemNotes,
        condition: 'New',
        grade: 'New',
        status: 'InStock',
        action: 'changeToNew',
        post_result: postValue,
      };

      await api.put('inventory_items', { items: [changeNew] });

      setItemCondition('New');
      form.setFieldsValue({ condition: 'New' });
      form.setFieldsValue({ grade: 'New' });
      form.setFieldsValue({ post_result: postValue });
      setItemInfo(() => ({ ...itemInfo, is_processed: false, condition: 'New', status: 'InStock', grade: 'New', post_result: postValue }));
      form.setFieldsValue({ is_processed: false });

      ChangeStorageRam(itemInfo, 'InUse');

      toast.success('Unit changed to New');
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangeUsed = async () => {
    try {
      const postValue = form.getFieldValue('postResult') || 'na';
      const changeUsed = {
        serial_number: serialNumber.toUpperCase(),
        notes: changeItemNotes,
        condition: 'Used',
        grade: 'U',
        status: 'InStock',
        action: 'changeToUsed',
        post_result: postValue,
      };
      await api.put('inventory_items', { items: [changeUsed] });
      setItemCondition('Used');
      form.setFieldsValue({ condition: 'Used' });
      form.setFieldsValue({ grade: 'U' });
      form.setFieldsValue({ post_result: postValue });
      setItemInfo(() => ({ ...itemInfo, is_processed: false, condition: 'Used', status: 'InStock', grade: 'U', post_result: postValue }));
      form.setFieldsValue({ is_processed: false });

      toast.success('Unit changed to Used');
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangePartNumber = async (newItemPartNumber) => {
    try {
      const newPartNumberInfo = await api.get(`/items/${newItemPartNumber}`);
      const changePartNumber = {
        serial_number: serialNumber.toUpperCase(),
        notes: changeItemNotes,
        item_id: newItemPartNumber,
        action: 'changePartNumber',
      };
      await api.put('inventory_items', { items: [changePartNumber] });
      setItemInfo((state) => ({
        ...state,
        item: { ...state.item, part_number: newPartNumberInfo.data.part_number, categoryId: newPartNumberInfo.data.category_id },
      }));
      setItemResults((state) => ({ ...state, itemDescription: newPartNumberInfo.data.description }));

      form.setFieldsValue({ itemId: newItemPartNumber });

      toast.success(`Unit's Part Number has been changed.`);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangeItem = async (type) => {
    setConfirmChangeModal(true);
    setChangeBeingDone(type);
  };

  const handleConfirmChangeItem = async () => {
    const notes = form.getFieldValue('changeNotes') || '';
    const itemId = form.getFieldValue('itemId') || null;
    const reserveType = form.getFieldValue('reserveType') || null;
    if (changeBeingDone === 'Part Number') {
      if (itemId !== null && notes.length >= 15) {
        handleChangePartNumber(itemId);
        form.setFieldsValue({ changeNotes: '' });
        form.setFieldsValue({ itemId: null });

        setFormChanged(false);
        setConfirmChangeModal(false);
      } else {
        toast.error('Please select a Part Number and write the reason for the change.');
      }
    } else if (changeBeingDone === 'Reservation') {
      if (reserveType !== null && notes.length >= 15) {
        handleChangeReserve(reserveType);
        form.setFieldsValue({ changeNotes: '' });
        form.setFieldsValue({ reserveType: null });

        setFormChanged(false);
        setConfirmChangeModal(false);
      } else {
        toast.error('Please select a Type of reservation and write the reason for the change.');
      }
    } else if (notes.length >= 15) {
      switch (changeBeingDone) {
        case 'Unreserve':
          handleChangeUnreserve();
          break;
        case 'New':
          handleChangeNew();
          break;
        case 'Used':
          handleChangeUsed();
          break;
        default:
          setChangeItemNotes(null);
          break;
      }
      form.setFieldsValue({ changeNotes: '' });
      form.setFieldsValue({ postResult: null });

      setFormChanged(false);
      setConfirmChangeModal(false);
    } else {
      toast.error('Please enter a minimum of 15 characters');
    }
  };

  const handleCancel = () => {
    form.setFieldsValue({ changeNotes: '' });
    setChangeItemNotes(null);
    setChangeBeingDone('');
    setConfirmChangeModal(false);
  };

  const cleanFieldsNotPassed = (fields) => {
    fields.map((field) => {
      if (field === 'keyboardPorts' || field === 'cosmeticsScreen') {
        form.setFieldsValue({ [field]: [] });
        return;
      }
      if (field === 'cpuModel' || field === 'acAdapterPartNumber') {
        form.setFieldsValue({ [field]: null });
        return;
      }
      if (field === 'batteryFile') {
        form.setFieldsValue({ [field]: [{ uid: null }] });
        return;
      }
      if (field === 'batteryFile2') {
        form.setFieldsValue({ [field]: [{ uid: null }] });
        return;
      }
      if (field === 'batteryFile3') {
        form.setFieldsValue({ [field]: [{ uid: null }] });
        return;
      }
      form.setFieldsValue({ [field]: '' });
    });
  };

  const changeDropdown = [
    {
      label: 'Item',
      key: 'Item',
      children: [
        {
          key: 'Part Number',
          label: 'Part Number',
          onClick: () => handleChangeItem('Part Number'),
          disabled:
            // inventoryItemsLogId !== null ||
            itemInfo.is_processed === false ||
            itemInfo.is_processed === null ||
            itemInfo.condition === 'For Parts' ||
            itemInfo.status === 'InUse' ||
            itemInfo.status === 'Reserved' ||
            itemInfo.status === 'InternalAsset' ||
            itemInfo.status === 'Gift' ||
            itemInfo.status === 'Allocated',
        },
      ],
    },
    {
      label: 'Condition',
      key: 'Condition',
      children: [
        {
          key: 'For Parts',
          label: 'For Parts',
          onClick: () => setConfirmChangeModalForParts(true),
          disabled:
            // inventoryItemsLogId !== null ||
            itemInfo.is_processed === false ||
            itemInfo.is_processed === null ||
            itemInfo.condition === 'For Parts' ||
            itemInfo.status === 'InUse' ||
            itemInfo.status === 'Reserved' ||
            itemInfo.status === 'InternalAsset' ||
            itemInfo.status === 'Gift' ||
            itemInfo.status === 'Allocated',
        },
        {
          key: 'New',
          label: 'New',
          onClick: () => handleChangeItem('New'),
          disabled:
            // inventoryItemsLogId !== null ||
            itemInfo.status === 'AwaitingShipment' ||
            itemInfo.status === 'InUse' ||
            itemInfo.status === 'Returning' ||
            itemInfo.status === 'Failed' ||
            itemInfo.status === 'Returned' ||
            itemInfo.status === 'NotFound' ||
            itemInfo.condition === 'For Parts' ||
            itemInfo.condition === 'New',
        },
      ],
    },
    {
      label: 'Status',
      key: 'Status',
    },
  ];

  if ([6].includes(itemInfo.item.category?.id)) {
    changeDropdown[1].children.push({
      key: 'Used',
      label: 'Used',
      onClick: () => handleChangeItem('Used'),
      disabled:
        // inventoryItemsLogId !== null ||
        itemInfo.status === 'AwaitingShipment' ||
        itemInfo.status === 'InUse' ||
        itemInfo.status === 'Returning' ||
        itemInfo.status === 'Failed' ||
        itemInfo.status === 'Returned' ||
        itemInfo.status === 'NotFound' ||
        itemInfo.condition === 'For Parts' ||
        itemInfo.condition === 'Used',
    });
  }

  if (!['InternalAsset', 'Gift', 'Allocated', 'Reserved'].includes(itemInfo.status)) {
    changeDropdown[2].children = [
      {
        key: 'Reserved',
        label: 'Reserved',
        onClick: () => handleChangeItem('Reservation'),
        disabled:
          // inventoryItemsLogId !== null ||
          itemInfo.status === 'AwaitingShipment' ||
          itemInfo.status === 'InUse' ||
          itemInfo.status === 'Returning' ||
          itemInfo.status === 'Returned' ||
          itemInfo.status === 'NotFound' ||
          itemInfo.condition === 'For Parts',
      },
    ];
  } else {
    changeDropdown[2].children = [
      {
        key: 'Unreserved',
        label: 'Unreserved',
        onClick: () => handleChangeItem('Unreserve'),
        disabled:
          // inventoryItemsLogId !== null ||
          itemInfo.status === 'AwaitingShipment' ||
          itemInfo.status === 'InUse' ||
          itemInfo.status === 'Returning' ||
          itemInfo.status === 'Returned' ||
          itemInfo.status === 'NotFound' ||
          itemInfo.condition === 'For Parts',
      },
    ];
  }

  const showWarranty = () => {
    setIsWarrantyVisible(true);
  };

  const closeWarranty = () => {
    setIsWarrantyVisible(false);
  };

  const showLocationModal = async () => {
    const locationLogData = await api.post('logs/getBySerialNumber', { serialNumber });
    setLocationLog(locationLogData.data);
    setIsLocationModalVisible(true);
  };

  const closeLocationModal = () => {
    setIsLocationModalVisible(false);
  };

  const warrantyColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Expire Date',
      dataIndex: 'expDate',
      render: (text) => moment(text).format('MMM DD, YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) =>
        text ? <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '20px' }} /> : <CloseCircleOutlined style={{ color: '#eb2f96', fontSize: '20px' }} />,
    },
  ];

  const locationColumns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text, row, index) => index + 1,
      width: 60,
    },
    {
      title: 'Previous Location',
      dataIndex: 'previous_data',
      width: 100,
    },
    {
      title: 'Current Location',
      dataIndex: 'new_data',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (text) => moment(text).format('MMM DD YYYY, h:mm a'),
      width: 100,
    },
  ];

  const modalLocationsLog = (
    <Modal title="Location Log" open={isLocationModalVisible} onOk={closeLocationModal} onCancel={closeLocationModal}>
      <Table
        style={{ marginTop: '5px' }}
        columns={locationColumns}
        dataSource={locationLog}
        size="small"
        bordered
        pagination={false}
        rowKey={(record) => record.id}
      />
    </Modal>
  );

  useBeforeUnload({
    when: formChanged,
    message: 'Are you sure you want to leave?',
  });

  const itemsBattery = [
    {
      label: 'Battery',
      key: 'Battery',
      children: (
        <Battery
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
  ];
  const itemsRam = [
    {
      label: 'Memory RAM',
      key: 'Memory RAM',
      children: (
        <GraphicCard
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
  ];

  const itemsStorage = [
    {
      label: 'Hard Drive',
      key: 'Hard Drive',
      children: (
        <HardDrive
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
  ];
  const itemsKeyboard = [
    {
      label: 'Keyboard Inspection',
      key: 'Keyboard Inspection',
      forceRender: true,
      children: (
        <Keyboard
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
          keyboardLanguageOptions={keyboardLanguageOptions}
          keyboardOptions={keyboardOptions}
          keyboardPortOptions={keyboardPortOptions}
          profile={profile}
        />
      ),
    },
    {
      label: 'Additional Information',
      key: 'Additional Information',
      forceRender: true,
      children: <Additional itemInfo={itemInfo} itemDisabled={itemDisabled} />,
    },
  ];
  const itemsComputer = [
    {
      label: 'Functionality Inspection',
      key: 'Hardware',
      forceRender: true,
      children: (
        <Hardware
          cameraOptions={cameraOptions}
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
          keyboardLanguageOptions={keyboardLanguageOptions}
          keyboardOptions={keyboardOptions}
          keyboardPortOptions={keyboardPortOptions}
          profile={profile}
          screenOptions={screenOptions}
          smartCardOptions={smartCardOptions}
          stylusOptions={stylusOptions}
          setCheckUsb={setCheckUsb}
          checkUsb={checkUsb}
          checkGraphicsCard={checkGraphicsCard}
          setCheckGraphicsCard={setCheckGraphicsCard}
          checkCpu={checkCpu}
          setCheckCpu={setCheckCpu}
        />
      ),
    },
    {
      label: 'Software Inspection',
      key: 'Software Inspection',
      forceRender: true,
      children: (
        <Software
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
    {
      label: 'Cosmetic Inspection',
      key: 'Cosmetic Inspection',
      forceRender: true,
      children: (
        <Cosmetic
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          cosmeticsScreenOptions={cosmeticsScreenOptions}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemResults={itemResults}
        />
      ),
    },
    {
      label: 'Additional Information',
      key: 'Additional Information',
      forceRender: true,
      children: <Additional itemInfo={itemInfo} itemDisabled={itemDisabled} />,
    },
  ];

  return (
    <Container>
      <Paper className={classes.paper}>
        <Prompt when={formChanged} message="You have unsaved changes, are you sure you want to leave?" />

        <Title level={3} style={{ display: 'inline' }}>
          Assessment Report for {serialNumber.toUpperCase()}
        </Title>
        <Button
          type="link"
          onClick={() => history.push(additionalFlag === true ? `/lotAssessment?id=${receivingId}&additionalFlag=true` : `/lotAssessment?id=${receivingId}`)}
        >
          Back to Lot Assessment
        </Button>
        {loading ? (
          <Spin tip="Loading...">
            <Alert
              message="Inspection page is loading. Hold on a second"
              description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
              type="info"
            />
          </Spin>
        ) : (
          <Form form={form} name="inspectionForm" onFinish={onFinish} onFinishFailed={onFinishFailed} onChange={() => handleFormChange()}>
            <ForPartsModal
              open={confirmChangeModalForParts}
              setOpen={setConfirmChangeModalForParts}
              loading={confirmLoading}
              handleOk={handleChangeToForParts}
            />

            <Modal
              title="Why are you changing this item?"
              open={confirmChangeModal}
              onOk={handleConfirmChangeItem}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <Title level={4}>{changeBeingDone} Change</Title>
              <Form.Item name="changeNotes" rules={[{ min: 15, message: 'Notes must be minimum 15 characters.' }]}>
                <TextArea
                  onBlur={(e) => setChangeItemNotes(e.target.value)}
                  showCount
                  maxLength={255}
                  allowClear
                  style={{ width: 465 }}
                  placeholder="Explain why you are changing this item"
                  rows={4}
                />
              </Form.Item>
              {changeBeingDone === 'New' || changeBeingDone === 'Used' || changeBeingDone === 'Not Inspected' ? (
                <>
                  <Title level={5}>POST</Title>
                  <p>Only change POST if needs changing</p>
                  <Form.Item name="postResult">
                    <Radio.Group buttonStyle="solid" name="postResult">
                      {changeBeingDone === 'Not Inspected' && (
                        <>
                          <Radio.Button value="passed">Passed</Radio.Button>
                          <Radio.Button value="failed">Failed</Radio.Button>
                          <Radio.Button value="new">New</Radio.Button>
                          <Radio.Button value="na">N/A</Radio.Button>
                        </>
                      )}
                      {changeBeingDone === 'New' && <Radio.Button value="new">New</Radio.Button>}
                      {changeBeingDone === 'Used' && <Radio.Button value="na">N/A</Radio.Button>}
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : (
                ''
              )}
              {changeBeingDone === 'Part Number' && (
                <Form.Item
                  name="itemId"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DebounceSelect
                    showSearch
                    allowClear
                    style={{ width: 465 }}
                    placeholder="Item / Part Number"
                    optionFilterProp="label"
                    fetchOptions={(value) => fetchItems(value, false, null, null, false)}
                  />
                </Form.Item>
              )}
              {changeBeingDone === 'Reservation' && (
                <Form.Item
                  name="reserveType"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select showSearch allowClear style={{ width: 465 }} placeholder="Reserve Type" options={reserveTypeOptions} />
                </Form.Item>
              )}
            </Modal>

            {itemInfo?.serial_number && (
              <Row style={{ marginRight: '10px' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="end" align="middle">
                <Space>
                  {!['Sold', 'Downstream', 'SentToFBA'].includes(itemInfo.status) && isAssignToSignedUser && (
                    <Form.Item>
                      <Dropdown menu={{ items: changeDropdown }} trigger={['click']}>
                        <Button href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                          Change <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Form.Item>
                  )}
                  {warrantyCheck.length !== 0 ? (
                    <Form.Item>
                      <Button style={{ background: '#3EB19E', borderColor: '#3EB19E', color: 'white' }} onClick={showWarranty} icon={<InfoCircleOutlined />}>
                        MFGR Warranty
                      </Button>
                      <Modal title="Warranty Details" open={isWarrantyVisible} onOk={closeWarranty} onCancel={closeWarranty} width={1000}>
                        <Table columns={warrantyColumns} dataSource={warrantyCheck} pagination={false} />
                      </Modal>
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <Button style={{ background: '#3EB19E', borderColor: '#3EB19E', color: 'white' }} onClick={showWarranty} icon={<InfoCircleOutlined />}>
                        MFGR Warranty
                      </Button>
                      <Modal title="Warranty Details" open={isWarrantyVisible} onOk={closeWarranty} onCancel={closeWarranty}>
                        <CloseCircleOutlined style={{ color: '#eb2f96', fontSize: '20px' }} /> No Warranty.
                      </Modal>
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Tooltip title={disabledMessage} color="orange">
                      <Badge color="volcano" dot={formChanged} style={{ width: 10, height: 10 }}>
                        <Button disabled={itemDisabled} type="primary" htmlType="submit" icon={<SaveOutlined />}>
                          Complete
                        </Button>
                      </Badge>
                    </Tooltip>
                  </Form.Item>

                  <Form.Item>
                    <Popconfirm
                      title="Are you sure to cancel this task?"
                      onConfirm={async () => {
                        async function setWarningOff() {
                          setFormChanged(false);
                          window.onbeforeunload = undefined;
                        }
                        await setWarningOff();
                        form.resetFields();
                        setSerialNumber('');
                        // searchInputRef.current.value = '';
                        // searchInputRef.current.focus();
                        setItemInfo(() => ({ item: { brand: {} }, category: {}, cpu: {} }));
                        history.push('/inventoryProcessing');
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button disabled={itemDisabled} type="primary" danger htmlType="button" icon={<CloseOutlined />}>
                        Cancel
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                  <Form.Item>
                    <Popconfirm
                      title="Are you sure to clear all fields?"
                      onConfirm={() => {
                        handleClear();
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        disabled={itemDisabled}
                        type="primary"
                        style={{ backgroundColor: 'orange', borderColor: 'orange' }}
                        htmlType="button"
                        icon={<CloseOutlined />}
                      >
                        Clear
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Space>
              </Row>
            )}
            {itemInfo?.serial_number && (
              <>
                <Descriptions title="" size="small" bordered>
                  <Descriptions.Item label="Original Specification" span={3}>
                    <Tooltip title={itemResults?.itemDescription} color="blue">
                      {itemResults?.itemDescription && `${itemResults?.itemDescription.substring(0, 180)}...`}
                    </Tooltip>
                  </Descriptions.Item>

                  <Descriptions.Item label="Inspected">
                    {itemInfo?.is_processed ? <Tag color="success">INSPECTED</Tag> : <Tag color="error">NOT INSPECTED</Tag>}
                  </Descriptions.Item>

                  <Descriptions.Item label="Location">
                    <Button type="link" onClick={showLocationModal} style={{ paddingLeft: 0 }}>
                      {`${itemInfo?.locations?.name || ''} ${itemInfo?.usersLocation?.name || ''}`}
                    </Button>
                    {modalLocationsLog}
                  </Descriptions.Item>

                  <Descriptions.Item label="Status">{itemInfo.status}</Descriptions.Item>

                  <Descriptions.Item label="POST">
                    {itemInfo?.post_result === 'passed' ? (
                      <Tag color={postResultColor[itemInfo?.post_result]}>{itemInfo?.post_result.toUpperCase()}</Tag>
                    ) : (
                      <Tag color="warning">NOT TESTED</Tag>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Condition">{itemInfo?.condition}</Descriptions.Item>
                  <Descriptions.Item label="Technician">{itemResults.technicianName}</Descriptions.Item>

                  <Descriptions.Item label="Company Grade">{itemInfo?.grade}</Descriptions.Item>
                  <Descriptions.Item label="Part Number">{itemInfo?.item.part_number}</Descriptions.Item>

                  <Descriptions.Item label="Last inspected at">{moment(itemInfo.last_inspection_at).format('MMM DD, YYYY HH:mm')}</Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Popover content={<div dangerouslySetInnerHTML={{ __html: gradingRules }} />} title="Cosmetic Grading" trigger="click">
                        <Button style={{ padding: 0 }} type="link">
                          Cosmetic Grade
                        </Button>
                      </Popover>
                    }
                  >
                    {itemResults.certifiedGrade}
                  </Descriptions.Item>

                  <Descriptions.Item label="Cosmetic Grade Description" span={2}>
                    {gradeDescription}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Popover content={<div dangerouslySetInnerHTML={{ __html: gradingRulesFunctions }} />} title="Functionality Grading" trigger="click">
                        <Button style={{ padding: 0 }} type="link">
                          Functionality Grade
                        </Button>
                      </Popover>
                    }
                  >
                    {gradeFunctionDescription[0]}
                  </Descriptions.Item>

                  <Descriptions.Item label="Functionality Grade Description" span={2}>
                    {gradeFunctionDescription[1]}
                  </Descriptions.Item>
                </Descriptions>
                <Divider />

                {[4].includes(itemInfo.item.category?.id) && [10].includes(itemInfo.item.subcategory?.id) ? (
                  <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsKeyboard} />
                ) : [9].includes(itemInfo.item.category?.id) && [4].includes(itemInfo.item.subcategory?.id) ? (
                  <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsBattery} />
                ) : [6].includes(itemInfo.item.category?.id) ? (
                  <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsStorage} />
                ) : [7, 15].includes(itemInfo.item.category?.id) ? (
                  <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsRam} />
                ) : (
                  <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsComputer} />
                )}
              </>
            )}
          </Form>
        )}
      </Paper>
    </Container>
  );
}
