import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Form, Modal, Select, Space, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';
import { Container } from './styles';
import { toast } from 'react-toastify';

const { Title } = Typography;
const { confirm } = Modal;

export default function SkidChangeLocation() {
  const [form] = Form.useForm();

  const [locationFromList, setLocationFromList] = useState([]);
  const [locationToList, setLocationToList] = useState([]);

  const [loading, setLoading] = useState(false);

  function compareLocationName(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  async function loadLocationsData() {
    const { data: locationsData } = await api.get('/locations');
    const fromLocations = locationsData
      .filter((location) => location.group === 'Skid')
      .filter((location) => location.quantity > 0)
      .sort(compareLocationName)
      .map((location) => ({
        value: location.id,
        label: `${location.name} (${location.quantity} items)`,
      }));
    setLocationFromList(fromLocations);

    const toLocations = locationsData
      .filter((location) => location.group === 'Skid')
      .filter((location) => location.quantity === 0)
      .sort(compareLocationName)
      .map((location) => ({
        value: location.id,
        label: location.name,
      }));
    setLocationToList(toLocations);
  }

  useState(() => {
    loadLocationsData();
  }, []);

  async function handleTransferSkidItems(data) {
    try {
      const { data: responseData } = await api.put('/locations/changeItemsLocation/skid', data);

      toast.success(`${responseData.quantity} items transfered successfully`);
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  }

  async function handleOnFinish(data) {
    setLoading(true);
    confirm({
      title: `Are you sure you want to transfer?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'When clicked the OK button, this dialog will be closed after 1 second',
      onOk() {
        handleTransferSkidItems(data);
        // return new Promise((resolve, reject) => {
        //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        // }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Link to="/changeLocation">
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back to Unit Change Location
              </Button>
            </Link>
          </Space>
          <Title level={3}>Skid Change Location</Title>
          <div />
        </div>
      </Card>

      <Card size="small">
        <Form form={form} onFinish={handleOnFinish}>
          <Space align="baseline">
            <Tooltip title="Only locations with units">
              <Form.Item hasFeedback name="fromLocationId" rules={[{ required: true, message: 'From location cannot be empty' }]}>
                <Select style={{ width: 150 }} showSearch placeholder="From" optionFilterProp="label" options={locationFromList} />
              </Form.Item>
            </Tooltip>

            <Tooltip title="Only locations with zero quantity">
              <Form.Item hasFeedback name="toLocationId" rules={[{ required: true, message: 'To location cannot be empty' }]}>
                <Select style={{ width: 150 }} showSearch placeholder="To" optionFilterProp="label" options={locationToList} />
              </Form.Item>
            </Tooltip>

            <Button type="primary" htmlType="submit" loading={loading}>
              Transfer
            </Button>
          </Space>
        </Form>
      </Card>
    </Container>
  );
}
