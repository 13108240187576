/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Modal, Popconfirm, Radio, Select, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';

import { DebounceSelect } from '~/components/DebounceSelect';
import InputText from '~/components/InputText';
import { fetchItems } from '~/pages/Inventory/Processing/utils/fetchItems';
import api from '~/services/api';

export default function Parts({ form1, profile, receivingItemInfo }) {
  const { Title } = Typography;

  // Dropdown List of Items
  // const itemsHD = itemList
  //   .filter((el) => el.is_active === true)
  //   .map((list) => ({ value: list.id, label: `${list.part_number} - ${list.name} - ${list.description}`, category_id: list.category_id }));

  // Table/List States
  const [inventoryItems, setInventoryItems] = useState([]);
  const [loadingRemoval, setLoadingRemoval] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warehouseLocationList, setWarehouseLocationList] = useState([]);
  const [warehouseWorkersIdList, setWarehouseWorkersIdList] = useState([]);

  const serialNumberPartsRef = useRef('');

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const receiving_item_id = receivingItemInfo.id;

    async function loadDropdownboxes() {
      // --------------------------------------------------------------------
      //  SET RELATED INVENTORY ITEMS TO THE RECEIVING ITEM
      // --------------------------------------------------------------------
      const inventoryItemsInfo = await api.get(`receivingItemsParts?receiving_item_id=${receiving_item_id}`);
      setInventoryItems(inventoryItemsInfo.data);

      const warehouseUsers = await api.get('/location-user-permission/listUsersInGroup/1');
      setWarehouseWorkersIdList(warehouseUsers.data.users);
    }

    loadDropdownboxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleRemoveItem(record) {
    setLoadingRemoval(true);
    const response = await api.delete(`/receivingItems/${record.inventory_items_parts.id}`);
    if (response.data.ok) {
      setInventoryItems((state) => state.filter((el) => el.inventory_items_parts.serial_number !== record.inventory_items_parts.serial_number));
    } else {
      toast.error(response.data.error);
    }
    setLoadingRemoval(false);
  }

  const columnsInventoryItems = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text, row, index) => index + 1,
    },
    {
      title: 'Item Part Number',
      dataIndex: ['inventory_items_parts', 'item', 'part_number'],
    },
    {
      title: 'CPU',
      dataIndex: ['inventory_items_parts', 'item', 'cpu', 'name'],
    },
    {
      title: 'Serial Number',
      dataIndex: ['inventory_items_parts', 'serial_number'],
      render: (text, record) => (
        <a rel="noopener noreferrer" target="_blank" href={`/inventoryProcessing/${record.inventory_items_id}`}>
          {text}
        </a>
      ),
    },
    {
      title: 'Received Date',
      dataIndex: ['inventory_items_parts', 'createdAt'],
      render: (text) => moment(text).format('MMM DD, YYYY'),
    },
    {
      title: 'Received By',
      dataIndex: ['inventory_items_parts', 'users', 'name'],
    },
    {
      title: 'POST',
      dataIndex: ['inventory_items_parts', 'post_result'],
    },
    {
      title: 'Grade',
      dataIndex: ['inventory_items_parts', 'grade'],
    },
    {
      title: 'Current Status',
      dataIndex: ['inventory_items_parts', 'status'],
    },
    {
      title: 'Current Location',
      dataIndex: ['inventory_items_parts', 'locations', 'name'],
      render: (text, row) => {
        if (text === 'Tech') return `${text} ${row.inventory_items_parts.usersLocation?.name || ''}`;
        return text;
      },
    },
    {
      title: 'Condition',
      dataIndex: ['inventory_items_parts', 'condition'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_text, record) => {
        if (profile.id === record.inventory_items_parts.users.id && moment.duration(moment().diff(record.inventory_items_parts.createdAt)).asHours() < 1) {
          return (
            <Popconfirm
              onConfirm={() => handleRemoveItem(record)}
              title="Are you sure you want to delete?"
              trigger="click"
              placement="left"
              okText="Yes"
              cancelText="No"
            >
              <button type="button" className="button-error pure-button">
                Remove
              </button>
            </Popconfirm>
          );
        }
        return '';
      },
    },
  ];

  const onFinishParts = async (values) => {
    const newInventoryItem = {};
    const newInventoryItemParts = {};

    const serialNumber = serialNumberPartsRef.current.value;
    if (!serialNumber) {
      toast.error('Invalid Serial Number');
      return;
    }

    const serialNumberFormated = serialNumber.trim().toUpperCase();

    try {
      const getItemInfo = await api.get(`/items/${values.itemPartsId}`);
      // const getItemInfo = itemsHD.find((item) => item.value === values.itemPartsId);

      newInventoryItem.branch_id = 1;

      newInventoryItem.post_result = values.postResultParts;

      if (values.postResultParts === 'passed') {
        newInventoryItem.status = 'ToBeInspected';
        newInventoryItem.condition = '';
        newInventoryItem.grade = 'TBI';
      } else if (values.postResultParts === 'failed') {
        newInventoryItem.status = 'Failed';
        newInventoryItem.condition = '';
        newInventoryItem.grade = 'FA';
      } else if (values.postResultParts === 'new') {
        newInventoryItem.status = 'InStock';
        newInventoryItem.condition = 'New';
        newInventoryItem.grade = 'New';
      } else if (values.postResultParts === 'na') {
        newInventoryItem.status = 'ToBeInspected';
        newInventoryItem.condition = '';
        newInventoryItem.grade = 'TBI';
      }
      newInventoryItem.received_by = profile.id;
      newInventoryItem.item_id = values.itemPartsId;
      newInventoryItem.serial_number = serialNumberFormated;
      newInventoryItem.is_ready = getItemInfo.data.category_id === 6;
      newInventoryItem.location_id = 219;
      newInventoryItem.user_id = profile.id;

      const inventoryItemsData = await api.post('inventory_items', newInventoryItem);

      const { id: inventoryItemId } = inventoryItemsData.data;

      if (getItemInfo.data.category_id === 6) {
        await api.put(`inventory_items/handleAssessmentComplete/${receivingItemInfo.id}`, [newInventoryItem]);
      }

      newInventoryItemParts.receiving_item_id = receivingItemInfo.id;
      newInventoryItemParts.inventory_items_id = inventoryItemId;
      await api.post('receivingItemsParts', newInventoryItemParts);

      toast.success('Item registered successfully');

      serialNumberPartsRef.current.value = '';
      serialNumberPartsRef.current.focus();

      setInventoryItems([
        ...inventoryItems,
        {
          inventory_items_id: inventoryItemId,
          inventory_items_parts: {
            serial_number: newInventoryItem.serial_number.trim().toUpperCase(),
            status: newInventoryItem.status,
            user_id: newInventoryItem.user_id,
            location_id: newInventoryItem.location_id,
            id: inventoryItemId,
            locations: {
              name: 'Tech',
            },
            usersLocation: {
              id: profile.id,
              name: profile.name,
            },
            condition: newInventoryItem.condition,
            post_result: newInventoryItem.post_result,
            createdAt: new Date(),
            users: { id: profile.id, name: profile.name },
            item: { part_number: getItemInfo.data.part_number },
          },
        },
      ]);
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  };

  const validateSerialNumber = async (SerialNumber) => {
    if (SerialNumber !== '' && SerialNumber !== null) {
      const upperCaseSerialNumber = SerialNumber.trim().toUpperCase();
      const inventoryItemsInfo = await api.post('inventory_items/showBySerialNumber', { serial_number: upperCaseSerialNumber });

      if (!inventoryItemsInfo.data.error) {
        toast.error('Serial Number already registered');
        return false;
      }
      return true;
    }
    return true;
  };

  if (loadingRemoval) {
    return (
      <Spin tip="Loading...">
        <Alert message="Hold on a second" description="This serial number is being removed." type="info" />
      </Spin>
    );
  }

  const handleBulkChangeLocation = async () => {
    try {
      const newLocationSelectedValue = form1.getFieldValue('warehouseLocation');
      if (newLocationSelectedValue !== null) {
        const response = await api.put(
          `locations/bulkChangeLocation/${newLocationSelectedValue}`,
          inventoryItems.map((el) => el.inventory_items_parts)
        );

        const inventoryItemsInfo = await api.get(`receivingItemsParts?receiving_item_id=${receivingItemInfo.id}`);
        setInventoryItems(inventoryItemsInfo.data);
        // setInventoryItems(() => response.data.newInventoryItems);
        toast.success(response.data.message);
        form1.setFieldsValue({ warehouseLocation: null });
        setIsModalOpen(!isModalOpen);
      } else {
        toast.error('Please select a location');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleOpenBulkChangeModal = async () => {
    if (warehouseLocationList.length === 0) {
      const locationList = await api.get('locations');
      const locationSelectList = locationList.data
        .filter((location) => location.location_type_id === 2)
        // .filter(location => location.location_type_id === 2 && location.is_parent === false)
        .map((location) => ({ value: location.id, label: `${location.name} - ${location.description}` })); // location_type_id 2 is for warehouse locations
      setWarehouseLocationList(() => locationSelectList);
    }
    form1.setFieldsValue({ warehouseLocation: null });
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Form
        form={form1}
        name="receivingPartsForm"
        onFinish={onFinishParts}
        onFinishFailed={() => toast.error('Please revise the alerts in the form, the data could not be submitted.')}
        layout="horizontal"
        initialValues={{ size: 'small' }}
        size="small"
      >
        <Divider />
        <Title level={3}>POST</Title>
        <Form.Item
          name="postResultParts"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group buttonStyle="solid" name="postResultParts">
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="new">New</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Title level={3}>Receiving Stock of Serial Numbers</Title>
        <Title level={5}>Part Number *</Title>
        <Form.Item
          name="itemPartsId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DebounceSelect
            showSearch
            allowClear
            style={{ width: 500 }}
            placeholder="Item / Part Number"
            optionFilterProp="label"
            fetchOptions={(el) => el && fetchItems(el, false, null, null, false)}
          />
          {/* <Select
            showSearch
            allowClear
            style={{ width: 500 }}
            placeholder="Item / Part Number"
            optionFilterProp="label"
            options={itemsHD}
            filterOption={(input, option) => option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0}
          /> */}
        </Form.Item>
        <Title level={5}>Please inform the Serial Number of the Received Item *</Title>
        {/* <Form.Item
          name="serialNumberParts"
          rules={[
            {
              required: true,
            },
            {
              validator: async (_, value) => ((await validateSerialNumber(value)) ? Promise.resolve() : Promise.reject('Serial number already registered')),
            },
          ]}
        > */}
        <InputText
          ref={serialNumberPartsRef}
          style={{ width: 500 }}
          placeholder="Serial Number"
          name="serialNumber"
          autoComplete="off"
          onBlur={(e) => validateSerialNumber(e.target.value)}
        />
        {/* </Form.Item> */}
        <Divider />

        <Divider />
        <Space>
          <Form.Item>
            <Button style={{ width: 300 }} block type="primary" htmlType="submit">
              Add Received Item to Inventory
            </Button>
          </Form.Item>

          {warehouseWorkersIdList.includes(profile?.id) && ( // Malek's, Ibrahim Khachab, Ibrahim Mistou, Abdulwahed's id
            <Form.Item>
              <Space>
                <Button onClick={() => handleOpenBulkChangeModal()} type="primary">
                  Change Location for this Receiving Lot
                </Button>
                <Tooltip title="This bulk change location will change all units that are located with Malek or Ibrahim to any Warehouse location.'">
                  <Tag color="blue" icon={<QuestionCircleOutlined />}>
                    HELP
                  </Tag>
                </Tooltip>
              </Space>
            </Form.Item>
          )}
        </Space>

        <Modal title="Change Location" onOk={handleBulkChangeLocation} onCancel={handleOpenBulkChangeModal} open={isModalOpen}>
          <Form.Item name="warehouseLocation" label="Location">
            <Select placeholder="Select a Warehouse Location." options={warehouseLocationList} showSearch allowClear optionFilterProp="label" />
            {/* <Input name="qrCode" type="password" autoComplete="new-password" placeholder="Please scan the QR Code" style={{ width: 300 }} /> */}
          </Form.Item>
        </Modal>
      </Form>

      <Divider />
      {inventoryItems.length > 0 && (
        <>
          <Title level={4}>Received Items Parts to Inventory</Title>

          <Divider />
          <Table
            columns={columnsInventoryItems}
            dataSource={inventoryItems}
            size="small"
            bordered
            pagination={false}
            rowKey={(record) => record.inventory_items_parts.id}
          />
        </>
      )}
    </>
  );
}
