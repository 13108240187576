import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Alert, Button, Card, Divider, Form, Modal, Popconfirm, Space, Table, Tag, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { ArrowLeftOutlined, CheckCircleTwoTone, HddOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import errorAudioFile from '~/assets/audio/error.mp3';
import successAudioFile from '~/assets/audio/success.mp3';

import InputText from '~/components/InputText';
import useDispatchPromise from '~/hooks/useDispatchPromise';
import api from '~/services/api';
import { getCurrentCountRequest } from '~/store/modules/itemsUnderMyName/actions';
import { checkReadingType } from './helpers/checkReadingType';
import { checkUserPermissionToChangeLocation } from './helpers/checkUserPermissionToChangeLocation';
import { handleItemTransfer } from './helpers/handleItemTransfer';
import { Container } from './styles';

const { Title } = Typography;

export default function ChangeLocation() {
  const [form] = Form.useForm();
  const profile = useSelector((state) => state.user.profile);
  // const dispatch = useDispatch();
  const dispatchPromise = useDispatchPromise();

  const qrCodeInputRef = useRef(null);

  const [session, setSession] = useState({ type: 'P/N' });
  // const [inputLabel, setInputLabel] = useState('Scan serial numbers or location/user QR code');
  const [helpText, setHelpText] = useState(
    'By default the location of scanned items will being changed to <strong>your name</strong> as soon as they are scanned. No further action will be required.'
  );
  const [itemList, setItemList] = useState([]);
  const [warehouseUserIds, setWarehouseUserIds] = useState([]);

  async function getWarehouseGroupUsers() {
    const { data } = await api.get('/location-user-permission/listUsersInGroup/1');
    setWarehouseUserIds(data.users);
  }

  useEffect(() => {
    getWarehouseGroupUsers();
  }, []);

  const onFinish = async (values) => {
    const readingInput = values.readingInput.trim();

    const readingData = await checkReadingType(readingInput);

    if (readingData.error) {
      new Audio(errorAudioFile).play();
      Modal.error({
        title: 'Warning',
        content: readingData.error,
      });
      form.setFieldsValue({ readingInput: '' });
      return;
    }

    switch (session.type) {
      case 'P/N':
        switch (readingData.type) {
          case 'P/N': {
            setHelpText(
              () =>
                'By default the location of scanned items will being changed to <strong>your name</strong> as soon as they are scanned. No further action will be required.'
            );

            if (readingData.fromLocationId === 219 && readingData.fromUserId === profile.id) {
              form.setFieldsValue({ readingInput: '' });
              qrCodeInputRef.current.focus();
              toast.warn('This item is already on this location. Nothing to be done.');
              return;
            }

            const userPermission = await checkUserPermissionToChangeLocation(readingData.fromLocationId, '219', readingData.fromUserId, profile.id);

            if (!userPermission.ok) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: userPermission.error,
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            const items = [
              {
                inventoryItemId: readingData.inventoryItemId,
                toLocationId: 219,
                toUserId: profile.id,
              },
            ];
            const transferResponse = await handleItemTransfer(items, 'now');

            if (!transferResponse.ok) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: transferResponse.error,
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            setItemList((state) => [
              ...state,
              {
                serial_number: readingData.serialNumber.trim().toUpperCase(),
                inventoryItemId: readingData.inventoryItemId,
                previous_location:
                  readingData.fromLocationId === 219 ? `${readingData.fromLocationName} ${readingData.fromUserName}` : readingData.fromLocationName,
                location: `Tech ${profile.name}`,
                status: 'done',
              },
            ]);

            new Audio(successAudioFile).play();
            toast.success('Change location is confirmed');
            form.setFieldsValue({ readingInput: '' });

            await api.get('countItemsUnderUser');

            await dispatchPromise(getCurrentCountRequest());

            break;
          }
          case 'LCT': {
            setSession({ type: 'LCT', toLocationId: readingData.toLocationId, toLocationName: readingData.toLocationName });
            setHelpText(
              () => `Items being transferred to <strong>${readingData.toLocationName}</strong> as soon as they are scanned. No further action will be required.`
            );
            form.setFieldsValue({ readingInput: '' });
            break;
          }
          case 'USR': {
            setSession({ type: 'USR', toUserName: readingData.toUserName, toUserId: readingData.toUserId, toUserEmail: readingData.toUserEmail });
            setHelpText(
              () =>
                `Items being transferred to <strong>${readingData.toUserName}</strong>. After scanning all serial numbers, you need to scan ${readingData.toUserName}’s QR code again to confirm the change location`
            );

            form.setFieldsValue({ readingInput: '' });
            // qrCodeInputRef.current.value = '';
            break;
          }

          default:
            break;
        }
        break;
      case 'LCT':
        switch (readingData.type) {
          case 'P/N': {
            if (readingData.fromLocationId === session.toLocationId) {
              form.setFieldsValue({ readingInput: '' });
              qrCodeInputRef.current.focus();
              toast.warn('This item is already on this location. Nothing to be done.');
              return;
            }

            const userPermission = await checkUserPermissionToChangeLocation(readingData.fromLocationId, session.toLocationId, readingData.fromUserId);

            if (!userPermission.ok) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: userPermission.error,
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            const items = [
              {
                inventoryItemId: readingData.inventoryItemId,
                toLocationId: session.toLocationId,
                toUserId: '',
              },
            ];
            // inventoryItemId, toLocationId, toUserId, transferType
            const transferResponse = await handleItemTransfer(items, 'now');

            if (!transferResponse.ok) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: transferResponse.error,
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            setItemList((state) => [
              ...state,
              {
                serial_number: readingData.serialNumber.trim().toUpperCase(),
                inventoryItemId: readingData.inventoryItemId,
                previous_location:
                  readingData.fromLocationId === 219 ? `${readingData.fromLocationName} ${readingData.fromUserName}` : readingData.fromLocationName,
                location: session.toLocationName,
                status: 'done',
              },
            ]);

            new Audio(successAudioFile).play();
            toast.success('Change location is confirmed');
            form.setFieldsValue({ readingInput: '' });

            break;
          }
          case 'LCT': {
            setSession({ type: 'LCT', toLocationId: readingData.toLocationId, toLocationName: readingData.toLocationName });
            setHelpText(
              () => `Items being transferred to <strong>${readingData.toLocationName}</strong> as soon as they are scanned. No further action will be required.`
            );
            form.setFieldsValue({ readingInput: '' });
            break;
          }
          case 'USR': {
            if (readingData.toUserEmail === profile.email) {
              setHelpText(
                () =>
                  'By default the location of scanned items will being changed to <strong>your name</strong> as soon as they are scanned. No further action will be required.'
              );
              setSession({ type: 'P/N' });
            } else {
              setHelpText(
                () =>
                  `Items being transferred to <strong>${readingData.toUserName}</strong>. After scanning all serial numbers, you need to scan ${readingData.toUserName}’s QR code again to confirm the change location`
              );
              setSession({ type: 'USR', toUserName: readingData.toUserName, toUserId: readingData.toUserId, toUserEmail: readingData.toUserEmail });
            }
            form.setFieldsValue({ readingInput: '' });
            break;
          }

          default:
            break;
        }
        break;
      case 'USR':
        switch (readingData.type) {
          case 'P/N': {
            if (readingData.fromLocationId === 219 && readingData.fromUserId === session.toUserId) {
              form.setFieldsValue({ readingInput: '' });
              qrCodeInputRef.current.focus();
              toast.warn('This item is already on this location. Nothing to be done.');
              return;
            }

            const isAlreadyAdded = itemList.find((item) => item.serial_number === readingData.serialNumber && item.status === 'pending');
            if (isAlreadyAdded) {
              toast.error('Item already added to be transferred');
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            const userPermission = await checkUserPermissionToChangeLocation(readingData.fromLocationId, 219, readingData.fromUserId || '', session.toUserId);

            if (!userPermission.ok) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: userPermission.error,
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            const items = [
              {
                inventoryItemId: readingData.inventoryItemId,
                toLocationId: 219,
                toUserId: session.toUserId,
              },
            ];
            // inventoryItemId, toLocationId, toUserId, transferType
            const transferResponse = await handleItemTransfer(items, 'pending');

            if (!transferResponse.ok) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: transferResponse.error,
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            setItemList((state) => [
              ...state,
              {
                serial_number: readingData.serialNumber.trim().toUpperCase(),
                inventoryItemId: readingData.inventoryItemId,
                previous_location:
                  readingData.fromLocationId === 219 ? `${readingData.fromLocationName} ${readingData.fromUserName}` : readingData.fromLocationName,
                location: `Tech ${session.toUserName}`,
                status: 'pending',
                toLocationId: 219,
                toUserId: session.toUserId,
              },
            ]);

            new Audio(successAudioFile).play();
            toast.warn('Change location is pending');
            form.setFieldsValue({ readingInput: '' });

            break;
          }
          case 'LCT': {
            new Audio(errorAudioFile).play();
            Modal.error({
              title: 'Warning',
              content: (
                <span
                  dangerouslySetInnerHTML={{ __html: `You need to scan <strong>${session.toUserName}’s QR code</strong> again to confirm the change location` }}
                />
              ),
            });
            form.setFieldsValue({ readingInput: '' });
            return;
          }

          case 'USR': {
            if (readingData.toUserEmail !== session.toUserEmail) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `You need to scan <strong>${session.toUserName}’s QR code</strong> again to confirm the change location`,
                    }}
                  />
                ),
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            const pendingItems = itemList.filter((item) => item.status === 'pending');

            const transferResponse = await handleItemTransfer(pendingItems, 'now');

            if (!transferResponse.ok) {
              new Audio(errorAudioFile).play();
              Modal.error({
                title: 'Warning',
                content: transferResponse.error,
              });
              form.setFieldsValue({ readingInput: '' });
              return;
            }

            setItemList((state) => state.map((item) => ({ ...item, status: 'done' })));

            setSession({ type: 'P/N' });
            setHelpText(
              () =>
                'By default the location of scanned items will being changed to <strong>your name</strong> as soon as they are scanned. No further action will be required.'
            );

            toast.success('Change location is confirmed');
            form.setFieldsValue({ readingInput: '' });
            break;
          }

          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const handleRemoveChecklistItem = async (rowInfo, rowIndex) => {
    try {
      const { inventoryItemId } = rowInfo;

      const undoResponse = await api.put(`locations/handleUndoLastChangeLocation/${inventoryItemId}`);

      if (!undoResponse.data.ok) {
        toast.error('Unable to undo this transfer');
        return;
      }

      setItemList((item) => item.filter((s, i) => i !== rowIndex));

      form.setFieldsValue({ readingInput: '' });
      qrCodeInputRef.current.focus();
      new Audio(successAudioFile).play();
      toast.success('Item transfer undone');

      await api.get('countItemsUnderUser');

      await dispatchPromise(getCurrentCountRequest());
    } catch (error) {
      new Audio(errorAudioFile).play();
      toast.error('Could not remove this item. Contact RBMS admin');
    }
  };

  const handleRestartTransfer = () => {
    setItemList((item) => item.filter((i) => i.status !== 'pending'));
    setSession({ type: 'P/N' });
    setHelpText(
      () =>
        'By default the location of scanned items will being changed to <strong>your name</strong> as soon as they are scanned. No further action will be required.'
    );

    form.setFieldsValue({ readingInput: '' });
    form.setFieldsValue({ readingInput: '' });
    qrCodeInputRef.current.focus();
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (_text, _row, index) => index + 1,
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
    },
    {
      title: 'Previous Location',
      dataIndex: 'previous_location',
    },
    {
      title: 'New Location',
      dataIndex: 'location',
    },
    {
      title: 'Status of change',
      dataIndex: 'status',
      align: 'center',
      render: (text) => (text === 'done' ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{ alignSelf: 'center' }} /> : <Tag color="orange">Pending</Tag>),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_text, row, idx) =>
        row.status === 'done' ? (
          <Popconfirm
            onConfirm={() => handleRemoveChecklistItem(row, idx)}
            title="Are you sure you want to delete?"
            trigger="click"
            placement="left"
            okText="Yes"
            cancelText="No"
          >
            <button type="button" className="button-error pure-button">
              Undo last transfer of this serial number
            </button>
          </Popconfirm>
        ) : (
          ''
        ),
    },
  ];

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Link to="/location">
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            {[...warehouseUserIds, 1].includes(profile.id) && (
              <Link to="/skid-change-location">
                <Button variant="contained" type="primary" icon={<HddOutlined />}>
                  Skids Change Location
                </Button>
              </Link>
            )}
            {['LCT', 'USR'].includes(session.type) && (
              <Button variant="contained" type="danger" onClick={handleRestartTransfer}>
                Start new transfer
              </Button>
            )}
          </div>
          <Title level={3}>Change units Location</Title>
          <div />
        </div>
      </Card>

      <Card elevation={3} size="small">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form form={form} name="nest-messages" onFinish={onFinish}>
            <Space direction="vertical">
              <Space wrap>
                <Form.Item
                  hasFeedback
                  name="readingInput"
                  label="Scan serial numbers or location/user QR code"
                  rules={[{ required: true, message: 'QR Code cannot be empty' }]}
                  style={{ margin: 0, padding: 0 }}
                >
                  <InputText
                    type="text"
                    name="readingInput"
                    ref={qrCodeInputRef}
                    autoComplete="off"
                    placeholder="Scan serial numbers or location/user QR code"
                    autoFocus
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                    style={{ width: 300 }}
                  />
                </Form.Item>
                <Alert message={<span dangerouslySetInnerHTML={{ __html: helpText }} />} type="info" showIcon />
              </Space>

              <Divider style={{ margin: 0, padding: 0 }}>Location Log</Divider>

              {session && session.type === 'USR' && (
                <Button variant="contained" type="danger" onClick={() => handleRestartTransfer()}>
                  Cancel transfer to {session.toUserName}
                </Button>
              )}

              <Table
                style={{ marginTop: '5px' }}
                columns={columns}
                dataSource={itemList}
                size="small"
                bordered
                pagination={false}
                scroll={{ y: 'calc(100vh - 22em)' }}
                rowKey={(record) => record.serial_number + record.previous_location + record.location}
              />
            </Space>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ visibility: 'hidden' }} />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Container>
  );
}
