import styled from 'styled-components';

export const GridContainer = styled.div`
  border: solid 1px ${(props) => props.theme['gray-300']};
  display: inline-block;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  min-width: 14.375rem;
`;

export const GridTitle = styled.div`
  border-bottom: solid 1px ${(props) => props.theme['gray-300']};
  color: ${(props) => props.theme['gray-800']};
  padding: 10px 15px;
  margin-bottom: 1rem;
`;

export const GridItems = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 1080px;
  height: auto;
`;

export const GridItem = styled.div`
  background-color: ${(props) => (props.empty ? props.theme['gray-300'] : props.theme['blue-500'])};
  border-radius: 5px;
  display: flex;
  margin-bottom: 1rem;

  div {
    width: 60px;
    height: 60px;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.white};
    }
  }
`;
