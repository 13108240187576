import { ArrowLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Button, Card, Checkbox, Dropdown, Pagination, Row, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';

import InputText from '~/components/InputText';
import { useDebouncedCallback } from '~/hooks/useDebounceCallback';
import api from '~/services/api';

import { Container } from './styles';
import AssignAssetDiagnosticRender from './utils/AssignAssetDiagnosticRender';
import AssignKBType from './utils/AssignKBType';
import AssignOtherPorts from './utils/AssignOtherPorts';
import ReportFields from './utils/ReportFields';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CheckboxGroup = Checkbox.Group;

export default function InventoryItemEdit(props) {
  const classes = useStyles();
  const searchTextRef = useRef('');

  const [loadingDownloadToExcel, setLoadingDownloadToExcel] = useState(false);

  const [inventoryItemHeader, setInventoryItemHeader] = useState({});
  const [inventoryItems, setInventoryItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [zeroQty, setZeroQty] = useState();
  const [keyboardPortOptions, setKeyboardPortOptions] = useState([]);

  const [filterStatus, setFilterStatus] = useState(['ToBeInspected', 'InStock', 'Failed']);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visibleGrade, setVisibleGrade] = useState(false);
  const [indeterminateStatusFilter, setIndeterminateStatusFilter] = useState(true);

  const [loading, setLoading] = useState(true);

  const [filterGrades, setFilterGrades] = useState(['A', 'B', 'C', 'D', 'OB', 'New', 'FP', 'U', 'FA', 'TBI']);
  const [indeterminateGradeFilter, setIndeterminateGradeFilter] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [paginationTotalItems, setPaginationTotalItems] = useState();
  const [paginationSize, setPaginationSize] = useState(25);
  const [paginationPage, setPaginationPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('descending');
  const [sortField, setSortField] = useState('created_at');

  const [checkAllStatus, setCheckAllStatus] = useState(false);
  const [checkAllGrade, setCheckAllGrade] = useState(true);

  const { Title, Text, Paragraph } = Typography;
  const width = 120;

  const handleSearch = useCallback(
    async (filterText) => {
      setLoading(true);
      const unitSerialNumber = filterText?.trim().toUpperCase() || searchTextRef.current.value.trim().toUpperCase() || '';
      const serialNumberFormatted =
        unitSerialNumber.substring(0, 4) === 'P/N:'
          ? unitSerialNumber.substring(unitSerialNumber.indexOf('S/N:') + 4)
          : unitSerialNumber.includes('S/N:')
          ? unitSerialNumber.substring(unitSerialNumber.indexOf('S/N:') + 4)
          : unitSerialNumber;

      searchTextRef.current.value = serialNumberFormatted;

      const params = new URLSearchParams();
      params.append('size', paginationSize);
      params.append('page', paginationPage - 1);
      params.append('serial_number', serialNumberFormatted || '');
      params.append('item_id', props.match.params.id);
      params.append('status', filterStatus.join() || '');
      params.append('grade', filterGrades.join() || '');
      params.append('orderDirection', sortOrder);
      params.append('orderField', sortField);

      const itemInventoryData = await api.put(`report/InventoryItemsByPN?${params}`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
      const otherPortsList = await api.get('other_ports_options');

      setInventoryItems(itemInventoryData.data.rows);
      setPaginationTotalItems(itemInventoryData.data.totalItems);
      setPaginationSize(itemInventoryData.data.sizePage);
      setPaginationPage(itemInventoryData.data.currentPage + 1);
      setKeyboardPortOptions(otherPortsList.data);
      setLoading(false);
    },
    [filterGrades, filterStatus, paginationPage, paginationSize, props.match.params.id, sortField, sortOrder]
  );

  useEffect(() => {
    async function loadDropdownboxes() {
      const { search } = props.location;
      const params = new URLSearchParams(search);
      const itemsData = await api.get(`items/${props.match.params.id}`);
      setSearchQuery(params.get('searchQuery'));
      setZeroQty(params.get('showZero'));

      const headerData = {
        item_description: `${itemsData.data.part_number} - ${itemsData.data.description}`,
        item_sku: itemsData.data.sku,
        branch: 1,
      };
      setInventoryItemHeader(headerData);

      handleSearch();
    }
    loadDropdownboxes();
  }, []); //eslint-disable-line

  async function downloadExcel() {
    try {
      const params = new URLSearchParams();
      params.append('size', Number.MAX_SAFE_INTEGER);
      params.append('page', 0);
      params.append('serial_number', '');
      params.append('item_id', props.match.params.id);
      params.append('status', filterStatus.join() || '');
      params.append('grade', filterGrades.join() || '');
      params.append('orderDirection', sortOrder);
      params.append('orderField', sortField);

      setLoadingDownloadToExcel(true);

      const itemInventoryData = await api.put(`report/InventoryItemsByPN?${params}`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
      const otherPortsList = await api.get('other_ports_options');

      const report = ReportFields(itemInventoryData.data.rows, otherPortsList.data);
      setLoading(false);

      const worksheet = XLSX.utils.json_to_sheet(report);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `${inventoryItemHeader.item_sku}.xlsx`);
    } catch (error) {
      toast.error('Error downloading excel');
    } finally {
      setLoadingDownloadToExcel(false);
    }
  }

  const statusOptions = [
    { value: 'InUse', label: 'In Use' },
    { value: 'ToBeInspected', label: 'To Be Inspected' },
    { value: 'ToBeInspected-RMA', label: 'To Be Inspected-RMA' },
    { value: 'Downstream', label: 'Downstream' },
    { value: 'AwaitingShipment', label: 'Awaiting Shipment' },
    { value: 'Sold', label: 'Sold' },
    { value: 'Returning', label: 'Returning' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Returned', label: 'Returned' },
    { value: 'Allocated', label: 'Allocated' },
    { value: 'InStock', label: 'In Stock' },
  ];

  const gradeOptions = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'New', label: 'New' },
    { value: 'OB', label: 'Open Box' },
    { value: 'FA', label: 'Failed' },
    { value: 'FP', label: 'For Parts' },
    { value: 'U', label: 'Used' },
    { value: 'TBI', label: 'To Be Inspected' },
  ];

  const onChangeFilter = (list, setFilterState, setIndeterminateState, setCheckAllState, options) => {
    setFilterState(list);
    setIndeterminateState(!!list.length && list.length < options.length);
    setCheckAllState(list.length === options.length);
  };

  const onCheckAllChangeFilter = (e, setFilterState, setIndeterminateState, setCheckAllState, options) => {
    setFilterState(e.target.checked ? options.map((location) => location.value) : []);
    setIndeterminateState(false);
    setCheckAllState(e.target.checked);
  };

  const menuStatus = [
    {
      key: '1',
      label: (
        <Checkbox
          indeterminate={indeterminateStatusFilter}
          onChange={(e) => onCheckAllChangeFilter(e, setFilterStatus, setIndeterminateStatusFilter, setCheckAllStatus, statusOptions)}
          checked={checkAllStatus}
        >
          Select all
        </Checkbox>
      ),
    },
    {
      key: '2',
      label: (
        <CheckboxGroup
          onChange={(list) => onChangeFilter(list, setFilterStatus, setIndeterminateStatusFilter, setCheckAllStatus, statusOptions)}
          value={filterStatus}
        >
          {statusOptions.map((status) => (
            <Row key={status.value}>
              <Checkbox value={status.value}>{status.label}</Checkbox>
            </Row>
          ))}
        </CheckboxGroup>
      ),
    },
  ];

  const menuGrade = [
    {
      key: '1',
      label: (
        <Checkbox
          indeterminate={indeterminateGradeFilter}
          onChange={(e) => onCheckAllChangeFilter(e, setFilterGrades, setIndeterminateGradeFilter, setCheckAllGrade, gradeOptions)}
          checked={checkAllGrade}
        >
          Select all
        </Checkbox>
      ),
    },
    {
      key: '2',
      label: (
        <CheckboxGroup
          onChange={(list) => onChangeFilter(list, setFilterGrades, setIndeterminateGradeFilter, setCheckAllGrade, gradeOptions)}
          value={filterGrades}
        >
          {gradeOptions.map((grade) => (
            <Row key={grade.value}>
              <Checkbox value={grade.value}>{grade.label}</Checkbox>
            </Row>
          ))}
        </CheckboxGroup>
      ),
    },
  ];

  const columns = [
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: 150,
      render: (text, record) => (
        <a rel="noopener noreferrer" target="_blank" href={`/inventoryProcessing/${record.id}`}>
          {text}
        </a>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      width,
      sorter: (a, b) => {
        if (a.hours === b.hours) return 0;
        if (a.hours === null) return 1;
        if (b.hours === null) return -1;
        return a.hours.localeCompare(b.hours, undefined, { numeric: true, sensitivity: 'base' });
      },
      sortDirections: ['descend', 'ascend'],
      sortOrder: sortField === 'hours' ? sortOrder : undefined,
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      width,
      sorter: (a, b) => a.condition.localeCompare(b.condition),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sortField === 'condition' ? sortOrder : undefined,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width,
      sorter: (a, b) => a.grade.localeCompare(b.grade),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sortField === 'grade' ? sortOrder : undefined,
    },
    {
      title: 'Location',
      dataIndex: ['locations', 'name'],
      width,
      ellipsis: {
        showTitle: false,
      },
      render: (text, row) => (
        <Tooltip title={`${row?.locations?.description} ${row?.usersLocation?.name || ''}`} color="blue">
          {text} {row?.usersLocation?.name || ''}
        </Tooltip>
      ),
      // sorter: (a, b) => a.condition.localeCompare(b.condition),
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sortField[0] === 'locations' ? sortOrder : undefined,
    },
    {
      title: 'Touch',
      dataIndex: 'touch',
      width,
    },
    {
      title: 'Stylus',
      dataIndex: 'stylus',
      width,
    },
    {
      title: 'Camera',
      dataIndex: 'camera',
      width,
    },
    {
      title: 'Storage',
      dataIndex: 'storage',
      width,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip title={text} color="blue">
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Storage 2',
      dataIndex: 'storage_2',
      width,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip title={text} color="blue">
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'RAM',
      dataIndex: 'ram',
      width,
    },
    {
      title: 'RAM 2',
      dataIndex: 'ram_2',
      width,
    },
    {
      title: 'Keyboard Language',
      dataIndex: 'keyboard_type_id',
      width: 200,
      render: (text) => AssignKBType(text),
    },
    {
      title: 'Other Ports',
      dataIndex: 'keyboard_ports',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => AssignOtherPorts(text, keyboardPortOptions),
    },
    {
      title: 'Audio',
      dataIndex: ['asset_diagnostic', 'function_audio'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'Barcode Reader',
      dataIndex: ['asset_diagnostic', 'function_barcode_reader'],
      width: 150,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'Bluetooth',
      dataIndex: ['asset_diagnostic', 'function_bluetooth'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'Fingerprint',
      dataIndex: ['asset_diagnostic', 'function_fingerprint'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'GPS',
      dataIndex: ['asset_diagnostic', 'function_gps'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'USB Ports',
      dataIndex: 'usb_ports',
      width,
    },
    {
      title: 'Mousepad',
      dataIndex: ['asset_diagnostic', 'function_mousepad'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'DVD',
      dataIndex: ['asset_diagnostic', 'function_optical_drive'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'Smartcard',
      dataIndex: ['asset_diagnostic', 'function_smartcard'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'WLAN',
      dataIndex: ['asset_diagnostic', 'function_wlan'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'WWAN',
      dataIndex: ['asset_diagnostic', 'function_wwan'],
      width,
      render: (text) => AssignAssetDiagnosticRender(text),
    },
    {
      title: 'OS',
      dataIndex: 'operating_system',
      width,
    },
    {
      title: 'Notes',
      dataIndex: 'description',
      width: 600,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip title={text} color="blue">
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Last Updated by',
      dataIndex: 'processed_by',
      width: 150,
    },
    {
      title: 'Last Updated at',
      dataIndex: 'updated_at',
      width: 150,
      render: (text) => moment(text).format('MMM DD, YYYY - HH:mm'),
      sorter: (a, b) => a.grade.localeCompare(b.grade),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sortField === 'grade' ? sortOrder : undefined,
    },
  ];

  const handleVisibleChange = (flag, setVisibleState) => {
    setVisibleState(() => flag);
    setIsFiltered(() => true);
  };

  const handleClearFilters = useCallback(() => {
    setIsFiltered(false);
    setFilterStatus(['ToBeInspected', 'InStock', 'Failed']);
    setFilterGrades(['A', 'B', 'C', 'D', 'OB', 'New', 'FP', 'U', 'FA', 'TBI']);
    setCheckAllGrade(() => true);
    setSortOrder(() => 'descending');
    setSortField(() => 'created_at');
  }, []);

  function handleChangePage(page, pageSize) {
    setPaginationPage(page);
    setPaginationSize(pageSize);
  }

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  function handleSort(_pagination, _filter, sorter) {
    setIsFiltered(() => true);
    setSortOrder(() => sorter.order);
    setSortField(() => sorter.field);
  }

  const debouncedDownloadExcel = useDebouncedCallback(downloadExcel, 500);

  return (
    <Container>
      <div id="item_description">
        <Space size={1}>
          <Link
            to={() => {
              if (searchQuery === null) {
                return `/inventory`;
              }
              return `/inventory?searchQuery=${searchQuery}&showZero=${zeroQty}`;
            }}
          >
            <Button variant="contained" type="primary" className={classes.button} icon={<ArrowLeftOutlined />}>
              Back
            </Button>
          </Link>
          <Link
            to={() => {
              if (searchQuery === null) {
                return `/inventoryCostReport?inventoryItemId=${props.match.params.id}&partNumber=${inventoryItemHeader.item_sku}`;
              }
              return `/inventoryCostReport?inventoryItemId=${props.match.params.id}&partNumber=${inventoryItemHeader.item_sku}&searchQuery=${searchQuery}&showZero=${zeroQty}`;
            }}
          >
            <Button variant="contained" type="primary" className={classes.button}>
              Cost Report
            </Button>
          </Link>
        </Space>
        <Title level={4}>
          <Tooltip title={inventoryItemHeader.item_description} color="blue">
            <Paragraph
              copyable={{
                text: inventoryItemHeader.item_description,
              }}
            >
              {inventoryItemHeader.item_description && `${inventoryItemHeader.item_description.substring(0, 120)}...`}
            </Paragraph>
          </Tooltip>
        </Title>
        <div />
      </div>
      <Card>
        <div id="item_search">
          <Space>
            <Text>Search Serial Number: </Text>
            <InputText
              placeholder="Search"
              ref={searchTextRef}
              onPressEnter={handleSearch}
              onBlur={(e) => {
                e.target.value = e.target.value.toUpperCase().trim();
              }}
              IconAfterProp={<MagnifyingGlass size={20} />}
              style={{ width: 300 }}
            />
            <Dropdown menu={{ items: menuStatus }} trigger={['click']} onOpenChange={(e) => handleVisibleChange(e, setVisibleStatus)} open={visibleStatus}>
              <Button variant="outlined" href="#" className="ant-dropdown-link" style={{ width: 140 }} onClick={() => setVisibleStatus(true)}>
                Status
              </Button>
            </Dropdown>
            <Dropdown menu={{ items: menuGrade }} trigger={['click']} onOpenChange={(e) => handleVisibleChange(e, setVisibleGrade)} open={visibleGrade}>
              <Button variant="outlined" href="#" className="ant-dropdown-link" style={{ width: 140 }} onClick={() => setVisibleGrade(true)}>
                Grade
              </Button>
            </Dropdown>
            {isFiltered && (
              <Tag
                closable
                color="error"
                onClose={(e) => {
                  e.preventDefault();
                  handleClearFilters();
                }}
              >
                Clear Filters
              </Tag>
            )}
          </Space>
          <Space>
            <Tooltip title="Download current table displayed. All filters will be applied.">
              <Tag color="grey" icon={<QuestionCircleOutlined />}>
                HELP
              </Tag>
            </Tooltip>
            <Button
              variant="outlined"
              type="primary"
              className="ant-dropdown-link"
              style={{ width: 200 }}
              onClick={debouncedDownloadExcel}
              loading={loadingDownloadToExcel}
            >
              Download to Excel
            </Button>
          </Space>
        </div>
      </Card>
      {loading ? (
        <Spin tip="Loading...">
          <Alert message="Loading inventory data" description="Once all items are loaded, you will be able to read items." type="info" />
        </Spin>
      ) : (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={inventoryItems}
            bordered
            pagination={false}
            scroll={{ x: 1500, y: 'calc(100vh - 23em)' }}
            rowKey="serial_number"
            size="small"
            onChange={handleSort}
          />
          <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}
