import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { GridContainer, GridItems, GridTitle, GridItem } from './styles';
import { Badge } from 'antd';

const sortAlphaNum = (a, b) => parseInt(a.name.replaceAll(/[A-Z]+/gi, '')) - parseInt(b.name.replaceAll(/[A-Z]+/gi, '')) || a.name.localeCompare(b.name); //eslint-disable-line

export default function GridBox({ locationGroup, locationList }) {
  const sortedLocationList = locationGroup === 'Skid' ? locationList : locationList.sort(sortAlphaNum);
  return (
    <GridContainer>
      {/* <pre>{JSON.stringify(locationList, null, 2)}</pre> */}
      <GridTitle>{locationGroup}</GridTitle>
      <GridItems>
        {sortedLocationList
          // .sort(sortAlphaNum)
          .map((location) => {
            return location.location_parent_id !== null ? (
              <div key={location.id}>
                <Link to={`/locationList?location=${location.location_parent_id}`}>{location.name}</Link>
              </div>
            ) : location.group === 'Skid' ? (
              <GridItem key={location.id} empty={Boolean(location.quantity === 0)}>
                <Badge
                  color={location.quantity > 0 ? '#1890ff' : 'gray'}
                  count={location.quantity}
                  showZero
                  overflowCount={9999}
                  style={{ zIndex: 999 }}
                  offset={[-15]}
                  size="small"
                >
                  <div>
                    <Link to={`/locationList?location=${location.id}`}>{location.name}</Link>
                  </div>
                </Badge>
              </GridItem>
            ) : (
              <GridItem key={location.id} empty={Boolean(location.quantity === 0)}>
                <div>
                  <Link to={`/locationList?location=${location.id}`}>{location.name}</Link>
                </div>
              </GridItem>
            );
          })}
      </GridItems>
    </GridContainer>
  );
}

GridBox.propTypes = {
  locationGroup: PropTypes.string.isRequired,
  locationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // rowKey: PropTypes.string.isRequired,
};
